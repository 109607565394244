import React from 'react'
import GroupPNG from '../assets/Group.png';


const StudentHeader = () => {
    return (
        <div>

            <div className='py-2 px-4'>
                <div className="flex flex-row items-center gap-2 pointer-cursor">
                    <div className="w-[24px] overflow-hidden">
                        <img
                            className="w-full h-full object-contain"
                            src={GroupPNG}
                            alt="logo"
                        />
                    </div>
                    <div className="font-bold text-3xl ">
                        norvel
                    </div>
                </div>

                
            </div>
            <hr></hr>





        </div>


    )
}

export default StudentHeader