




import React, { useState, useRef, useEffect } from 'react';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import PersonIcon from '@mui/icons-material/Person';

import SideBar from '../sidebar/SideBar';
import Logout from '../authetication/Logout';
import { useNavigate } from 'react-router-dom';
import { useDropdown } from '../DropdownContext/DropdownContext';
import GroupPNG from '../../assets/Group.png';
import { CLIENT_ID, REDIRECT_URI } from '../../config/config';
import { useLocation } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';



const Header = ({ fetchCountries, setFeesTo, setMarks }) => {
    const [isSidebarVisible, setSidebarVisible] = useState(false);
    const [userInfo, setUserInfo] = useState(null);
    const dropdownRef = useRef(null);
    const { isDropdownVisible, setDropdownVisible } = useDropdown(); // Access context state
    const navigate = useNavigate();
    const location = useLocation();
    const isHomePage = location.pathname === '/';
   

    const handleLogoClick = () => {
        localStorage.removeItem('selectedEducationType');

        navigate('/');
    };

    useEffect(() => {
        const accessToken = localStorage.getItem('access');
        const refreshToken = localStorage.getItem('refresh');
        const storedUserInfo = localStorage.getItem('user_info');

        if (accessToken && refreshToken && storedUserInfo) {
            setUserInfo(JSON.parse(storedUserInfo));
        }
    }, []);







    const toggleSidebar = () => {
        setSidebarVisible(!isSidebarVisible);
    };

    const toggleDropdown = () => {
        setDropdownVisible(!isDropdownVisible);
    };

    const handleLogin = () => {
        const authUrl = `https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&scope=email%20profile&service=lso&o2v=2&ddm=0&flowName=GeneralOAuthFlow`;
        window.location.href = authUrl;
    };
    const navItems = [
        { name: "Home", path: "/" },
        { name: "Courses", path: "/home" },
        { name: "Student Connect", path: "" },
        { name: "Find Accommodation", path: "" },
        { name: "Education Loan", path: "" },
        { name: "Forex Money Transfer", path: "" },
    ];

    return (
        <div className={isHomePage ? '' : ''}>
            <div className=''>
                <div className="flex items-center justify-between py-2.5 px-3 lg:px-5 xl:28">
                    <div className="flex flex-row gap-2 pointer-cursor" onClick={handleLogoClick} style={{ cursor: 'pointer' }}>
                        <div className="w-[24px] overflow-hidden">
                            <img
                                className="w-full h-full object-contain"
                                src={GroupPNG}
                                alt="logo"
                            />
                        </div>
                        <div className='font-bold text-3xl text-black'>
                            norvel
                        </div>
                    </div>
                    <nav className="xl:px-6 px-3 py-3 hidden lg:flex justify-center flex-1">
                        <ul className="flex space-x-4 font-pt-sans text-[10px] xl:text-[16px]">
                            {navItems.map((item) => (
                                <li key={item.name}>
                                    <button
                                        className={`px-3 py-1.5 rounded-full transition duration-300 ${(item.name === "Courses" && (location.pathname.startsWith("/home") || location.pathname.startsWith("/course"))) ||
                                                location.pathname === item.path
                                                ? "bg-black text-white shadow-md"
                                                : "text-gray-700 hover:text-black"
                                            }`}
                                        onClick={() => navigate(item.path)}
                                    >
                                        {item.name}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </nav>
                    <div className="flex gap-4 items-center hidden lg:flex relative">
                        {userInfo ? (
                            <div className="flex gap-2 items-center cursor-pointer" onClick={toggleDropdown}>
                                <img
                                    src={userInfo.user_info?.profile_img || './default-profile.png'}
                                    alt="Profile"
                                    className="rounded-full w-6 h-6"
                                />
                                <KeyboardArrowDownOutlinedIcon style={{ color: 'gray', fontSize: '16' }} />
                            </div>
                        ) : (
                            <div className="flex gap-2 items-center bg-white rounded-full px-2 py-1 cursor-pointer" onClick={handleLogin}>

                                <div className="lg:hidden">
                                    <PersonIcon style={{ color: 'black', fontSize: '26' }} />
                                </div>

                                {/* Desktop view (1024px and above) */}
                                <div className='hidden lg:flex items-center gap-1 bg-black rounded-[20px] xl:py-2 xl:px-5 px-4 py-2'>
                                    <PersonIcon style={{ color: 'white', fontSize: '16' }} />
                                    <div className="text-[13px] pt-sans-bold text-white">Login</div>
                                </div>

                            </div>
                        )}

                    </div>
                    {isSidebarVisible && <SideBar isVisible={isSidebarVisible} onClose={toggleSidebar} />}
                </div>
            </div>

            {/* {isDropdownVisible && (
            <div ref={dropdownRef} className="absolute top-11 md:top-12 right-4  md:right-8 px-2 md:px-6 bg-white border rounded shadow-lg">
              <div className="py-1 md:py-1  cursor-pointer text-[13px] pt-sans-regular"><Logout /></div>
            </div>
          )} */}
        </div>
    );
};

export default Header;


