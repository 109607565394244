
import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router';
import tokenInstance from '../components/axiosInstance/tokenInstance';
import { createClient } from 'agora-rtc-sdk-ng';
import AgoraRTC from 'agora-rtc-sdk-ng';
import { useMentorContext } from './MentorContext';
import CallIcon from '@mui/icons-material/Call';
import CloseIcon from '@mui/icons-material/Close';
import OutlinedFlagIcon from '@mui/icons-material/OutlinedFlag';
import MicOffIcon from '@mui/icons-material/MicOff';
import MicIcon from '@mui/icons-material/Mic';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import { motion } from "framer-motion";


import { Modal, Box } from '@mui/material';
import Bottom from './Bottom';

const MentorCall = () => {
    const [mentorCallResponse, setMentorCallResponse] = useState([]);
    const [mentorCallRequst, setMentorCallRequst] = useState([]);
    const [mentorId, setMentorId] = useState();
    const [socket, setSocket] = useState(null);
    const [agoraToken, setAgoraToken] = useState(null);
    const [client, setClient] = useState(null);
    const [isJoined, setIsJoined] = useState(false);
    const [channelName, setChannelName] = useState(null);
    const [uid, setUid] = useState(null);
    const [isReadyToJoin, setIsReadyToJoin] = useState(false);
    const [isMuted, setIsMuted] = useState(false);
    const [localAudioTrack, setLocalAudioTrack] = useState(null); // Track local audio
    const navigate = useNavigate();
    const [requestSocket, setRequestSocket] = useState(null);
    const [notifyRequstList, setNotifyRequstList] = useState([])
    const [selectedRequests, setSelectedRequests] = useState([]);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [nextPage, setNextPage] = useState(null); // Store next page URL
    const [loading, setLoading] = useState(false); // Track loading state
    const observerRef = useRef(null); // Ref for infinite scroll trigger
    const [isSpeakerOn, setIsSpeakerOn] = useState(false);
   


    const location = useLocation();
    const roomId = location.state?.roomId.id




    const appId = "f9396bbbaad64783abd978a28e727ce2";

    console.log(roomId, "getroomid")





   
    const fetchRequestList = async (url = `calls/api/v1/voice-call-room-request-list/?room_id=${roomId}&limit=10`) => {
        if (!url || loading) return; // Avoid unnecessary API calls

        setLoading(true);
        try {
            const response = await tokenInstance.get(url);

            setMentorCallRequst((prevRequests) => {
                const newRequests = response.data.results.filter(
                    (newRequest) => !prevRequests.some((req) => req.id === newRequest.id)
                );
                return [...prevRequests, ...newRequests]; // Append new unique results
            });

            setNextPage(response.data.next); // Update next page URL
        } catch (err) {
            console.error("Error fetching request list:", err.message);
        }
        setLoading(false);
    };
    // useEffect(() => {
    //     if (!nextPage) return;

    //     observerRef.current = new IntersectionObserver(
    //         (entries) => {
    //             if (entries[0].isIntersecting && nextPage) {
    //                 fetchRequestList(nextPage); // Fetch next page when user scrolls down
    //             }
    //         },
    //         { threshold: 1 }
    //     );

    //     if (observerRef.current && document.getElementById("load-more-trigger")) {
    //         observerRef.current.observe(document.getElementById("load-more-trigger"));
    //     }

    //     return () => {
    //         if (observerRef.current) observerRef.current.disconnect();
    //     };
    // }, [nextPage]);



    useEffect(() => {
        const observerCallback = (entries) => {
            if (entries[0].isIntersecting && nextPage) {
                fetchRequestList(nextPage); // Fetch the next page
            }
        };

        if (!observerRef.current) {
            observerRef.current = new IntersectionObserver(observerCallback, { threshold: 1 });
        }

        const triggerElement = document.getElementById("load-more-trigger");
        if (triggerElement) {
            observerRef.current.observe(triggerElement);
        }

        return () => {
            if (observerRef.current) {
                observerRef.current.disconnect();
                observerRef.current = null;
            }
        };
    }, [nextPage]);





    const callReject = async (requestId) => {
        try {
            await tokenInstance.put(`calls/api/v1/voice-call-room-request-update/${requestId}/`, {
                status: "Rejected",
            });
        } catch (err) {
            console.error("Error rejecting call request:", err.message);
        }
    };

    const userInfo = JSON.parse(localStorage.getItem('user_info'));
    const userId = userInfo?.id;

    useEffect(() => {
        if (userId) {
            const wsUrl = `wss://norvelbackend.site/ws/voice-call-token/voice_call_room_${userId}/`;
            const newSocket = new WebSocket(wsUrl);
            setSocket(newSocket);

            newSocket.onopen = () => {
                console.log("WebSocket connection established.");
            };

            newSocket.onmessage = (event) => {
                try {
                    const message = JSON.parse(event.data);
                    if (message.type === "voice_call_websocket_receiver") {
                        setAgoraToken(message.event.token);
                        setUid(message.event.uid);
                        setChannelName(message.event.channel_name);
                        setIsReadyToJoin(true); // State to indicate Agora parameters are ready
                    }
                } catch (error) {
                    console.error("Error parsing WebSocket message:", error);
                }
            };

            newSocket.onerror = (error) => {
                console.error("WebSocket error:", error);
            };

            newSocket.onclose = (event) => {
                console.log("WebSocket connection closed:", event);
            };

            return () => {
                newSocket.close();
            };
        }
    }, [userId]);
    useEffect(() => {
        if (userId) {
            // WebSocket for voice call requests
            const wsRequestUrl = `wss://norvelbackend.site/ws/voice-call-requests/voice_call_requests_${userId}/`;
            const newRequestSocket = new WebSocket(wsRequestUrl);
            setRequestSocket(newRequestSocket);

            newRequestSocket.onopen = () => {
                console.log('Request WebSocket connection established.');
            };

            newRequestSocket.onmessage = (event) => {
                console.log('Message received:', event.data);
                fetchRequestList();
            };

            newRequestSocket.onerror = (error) => {
                console.error('Request WebSocket error:', error);
            };

            newRequestSocket.onclose = (event) => {
                console.log('Request WebSocket connection closed:', event);
            };

            // Clean up on unmount
            return () => {
                newRequestSocket.close();
            };
        }
    }, [userId]);

    const joinAgoraChannelMentor = async () => {
        if (!agoraToken || !channelName || !uid) {
            console.error("Missing Agora token, channel name, or UID.");
            return;
        }

        try {
            const agoraClient = createClient({ mode: 'rtc', codec: 'vp8' });
            setClient(agoraClient);

            // Create and publish local audio track
            const track = await AgoraRTC.createMicrophoneAudioTrack();
            setLocalAudioTrack(track);
            await agoraClient.join(appId, channelName, agoraToken, uid);
            await agoraClient.publish([track]);
            console.log("Mentor audio track published.");

            // Handle remote user subscriptions
            agoraClient.on("user-published", async (user, mediaType) => {
                await agoraClient.subscribe(user, mediaType);
                console.log("Subscribed to remote user:", user.uid);

                if (mediaType === "audio") {
                    const remoteAudioTrack = user.audioTrack;
                    remoteAudioTrack.play();
                    console.log("Playing remote audio from user:", user.uid);
                }
            });

            agoraClient.on("user-unpublished", (user) => {
                console.log("Remote user unpublished:", user.uid);
            });

            setIsJoined(true);
        } catch (error) {
            console.error("Error joining Agora channel (Mentor):", error);
        }
    };

    const toggleMute = () => {
        if (localAudioTrack) {
            if (isMuted) {
                localAudioTrack.setEnabled(true); // Unmute
                console.log("Microphone unmuted.");
            } else {
                localAudioTrack.setEnabled(false); // Mute
                console.log("Microphone muted.");
            }
            setIsMuted(!isMuted);
        }
    };
    const statusUpdate = async (requestId) => {
        try {
            const response = await tokenInstance.put(`calls/api/v1/voice-call-room-request-update/${requestId}/`, {
                "status": "Accepted"
            });
            console.log(response.data);

        } catch (err) {
            console.log("error in update status api", err.message);
        }
    };

    useEffect(() => {
        if (isReadyToJoin && agoraToken && channelName && uid) {
            joinAgoraChannelMentor();
        }
    }, [isReadyToJoin, agoraToken, channelName, uid]);
    // const endCall = async () => {
    //     if (client) {
    //         try {
    //             await client.leave();
    //             client.remoteUsers.forEach((user) => {
    //                 if (user.audioTrack) {
    //                     user.audioTrack.stop();
    //                     user.audioTrack.close();
    //                 }
    //             });
    //             if (localAudioTrack) {
    //                 localAudioTrack.stop();
    //                 localAudioTrack.close();
    //             }
    //             setClient(null);
    //             setLocalAudioTrack(null);
    //             setIsJoined(false);
    //             console.log("Call ended successfully.");
    //             navigate("/room");
    //         } catch (error) {
    //             console.error("Error ending the call:", error);
    //         }
    //     }
    // };

    const callEnd = async () => {
        if (!roomId) {
            console.error("Room ID is missing.");
            return;
        }

        try {
            const response = await tokenInstance.post("calls/api/v1/disconnect_voice_call/", {
                room: roomId,
            });

            if (response.status === 200) {
                console.log("Call disconnected successfully.");


                if (client) {
                    await client.leave();
                    client.remoteUsers.forEach((user) => {
                        if (user.audioTrack) {
                            user.audioTrack.stop();
                            user.audioTrack.close();
                        }
                    });

                    if (localAudioTrack) {
                        localAudioTrack.stop();
                        localAudioTrack.close();
                    }

                    setClient(null);
                    setLocalAudioTrack(null);
                    setIsJoined(false);
                }

                navigate("/room");
            } else {
                console.error("Failed to disconnect call. Status:", response.status);
            }
        } catch (error) {
            console.error("Error ending the call:", error);
        }
    };


    // Handle selection toggle





    useEffect(() => {
        const handleBeforeUnload = (event) => {
            event.preventDefault();
            callEnd()




            return "";
        };


        // Attach the event listener
        window.addEventListener("beforeunload", handleBeforeUnload);

        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, []);
    const toggleSpeaker = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

            // Get all audio output devices
            const devices = await navigator.mediaDevices.enumerateDevices();

            // Find audio output devices (speakers)
            const speakerDevices = devices.filter(device => device.kind === 'audiooutput');

            if (speakerDevices.length > 0) {
                const speaker = speakerDevices[0].deviceId; // Get the first speaker
                const audioElement = document.querySelector('audio'); // Assuming you have an audio element for the call

                if (isSpeakerOn) {
                    // Switch to earpiece (you would use an earpiece or default device here)
                    audioElement.setSinkId(null); // This should revert to the default (earpiece or system audio)
                } else {
                    // Switch to speakerphone (using the first available speaker)
                    audioElement.setSinkId(speaker);
                }
                setIsSpeakerOn(!isSpeakerOn); // Toggle the state
            }
        } catch (error) {
            console.error('Error accessing media devices:', error);
        }
    };








    return (
        <div>





            <div
                className='flex  flex-col  pt-6 min-h-screen '
                style={{ backgroundImage: "url('/bgimg.png')", backgroundSize: "cover", backgroundPosition: "center" }}>
                <div className="flex flex-col items-center justify-center rounded-md  ">
                    {isJoined ? (
                        <div className='flex flex-col items-center justify-center  pt-32 md:pt-0 '>
                            <div className='flex items-center gap-6 w-full justify-center'>
                                <div className='flex items-center justify-center  md:pt-0  flex-col'>
                                    <div className='relative w-[100px] h-[100px] md:w-[200px] md:h-[200px]'>
                                        <div className='absolute inset-0 border-2 border-green-500  rounded-full animate-opacity-pulse m-[-12px]'></div>
                                        <div className='absolute inset-0 border-2 border-green-500  rounded-full animate-opacity-pulse m-[-6px]'></div>

                                        <img src="/img2.jpg" className='w-[100%] h-[100%] object-cover rounded-full' />
                                    </div>

                                    <div className='text-[11px] pt-4 pt-sans-regular text-white '>
                                        ( you)
                                    </div>
                                </div>
                                <div className='flex items-center justify-center  md:pt-0  flex-col'> <img src="/icon.png" className='w-[100%] h-[100%] object-cover ' /></div>
                                <div className='flex items-center justify-center  md:pt-0  flex-col'>
                                    <div className='relative w-[100px] h-[100px] md:w-[200px] md:h-[200px]'>
                                        <div className='absolute inset-0 border-2 border-green-500  rounded-full animate-opacity-pulse m-[-12px]'></div>
                                        <div className='absolute inset-0 border-2 border-green-500  rounded-full animate-opacity-pulse m-[-6px]'></div>

                                        <img src="/img3.jpg" className='w-[100%] h-[100%] object-cover rounded-full' />
                                    </div>

                                    <div className='text-[11px] pt-4 pt-sans-regular text-white '>
                                        ( student)
                                    </div>
                                </div>
                            </div>
                            {/* icons */}


                        </div>


                    ) : (
                        <>
                            <div className='flex items-center justify-center  pt-32 md:pt-0  flex-col'>
                                <div className='relative w-[150px] h-[150px] md:w-[200px] md:h-[200px]'>
                                    <div className='absolute inset-0 border-2 border-green-500  rounded-full animate-opacity-pulse m-[-12px]'></div>
                                    <div className='absolute inset-0 border-2 border-green-500  rounded-full animate-opacity-pulse m-[-6px]'></div>
                                    {/* Outer border with pulse effect */}
                                    <img src="/img2.jpg" className='w-[100%] h-[100%] object-cover rounded-full' />
                                </div>

                                <div className='text-[11px] pt-4 pt-sans-regular text-white '>
                                    ( you)
                                </div>
                            </div>
                        </>
                    )}
                </div>
                {/* icons */}

                <div className='mt-auto  '>
                    {isJoined && (<div className=' py-4   flex item-center justify-center gap-8 px-4'>

                        <div>
                            <div
                                className='cursor-pointer'
                                onClick={toggleMute}

                            >

                                {isMuted ? <div className='bg-white opacity-45 rounded-full px-4 py-4'><MicOffIcon style={{ fontSize: '25px' }} /></div> : <div className='bg-white opacity-45 rounded-full px-4 py-4'>< MicIcon style={{ fontSize: '25px' }} /> </div>}
                            </div>
                        </div>
                        <div>
                            <div
                                className=''

                            >


                                <div className='bg-white opacity-45 rounded-full px-4 py-4'>
                                    <VolumeUpIcon style={{ fontSize: '25px' }} />
                                </div>

                            </div>
                        </div>
                        <div>
                            <div
                                onClick={callEnd}
                                className=" py-4 px-4 text-[12px] rounded-full cursor-pointer bg-red-600 text-white"

                            >
                                <CloseIcon style={{ fontSize: '25px' }} />

                            </div>
                        </div>

                    </div>)}
                    <motion.div 
                        className='bg-gray-100' 
                        style={{ borderRadius: "20px 20px 0 0" }}
                        initial={{ y: "100%" }}  // Start off-screen (bottom)
                        animate={{ y: 0 }}       // Slide up into view
                        transition={{ duration: 0.5, ease: "easeOut" }} 
                    >


                    <div className='bg-gray-100  ' style={{ borderRadius: "20px 20px 0 0" }}>
                        <div className=' h-[300px] overflow-y-auto px-4 '>
                            <div className="pt-sans-regular px-4 py-2 text-[14px]">Waiting list</div>
                            {mentorCallRequst?.map((request, index) => {
                                return (
                                    <div key={index} className="flex justify-between items-center w-full my-2 px-4 py-2   border border-gray-300 shadow-md  ">
                                        <div className='flex gap-2 items-center'>
                                            <div className='w-8 h-8'>
                                                <img src="/img3.jpg" className='w-[100%] h-[100%] object-cover rounded-full' />
                                            </div>
                                            <div className='flex flex-col'>
                                                <div className="text-[13px] pt-sans-bold">{request.student?.first_name}</div>
                                            </div>
                                        </div>
                                        <div className='flex gap-2 items-center'>
                                            <div
                                                className="bg-green-400 text-white rounded-full text-[12px] py-1 px-1  cursor-pointer"
                                                onClick={() => statusUpdate(request.id)}
                                            >
                                                <CallIcon style={{ fontSize: '20px' }} />

                                            </div>
                                            <div
                                                className="bg-red-400 rounded-full  text-white text-[12px] py-1 px-1  cursor-pointer"
                                                onClick={() => callReject(request.id)}
                                            >
                                                <CloseIcon style={{ fontSize: '20px' }} />
                                            </div>
                                            <div

                                            >
                                                <OutlinedFlagIcon />
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    </motion.div>
                </div>





            </div>
            <Bottom />









        </div>
    );
};

export default MentorCall;












