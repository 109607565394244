
import Filters from '../filters/Filters'
import axiosInstance from '../axiosInstance/axiosInstance'
import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import FilterTab from '../filters/FilterTab';
import { useDropdown } from '../DropdownContext/DropdownContext';
import CourseFilter from '../tabs/CourseFilter';
import { motion, AnimatePresence } from 'framer-motion';
import AgencyFilter from '../filters/AgencyFilter';
import CallIcon from '@mui/icons-material/Call';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import { ReactComponent as MoneySVG } from '../../assets/money.svg';
import { ReactComponent as LakhsSVG } from '../../assets/lakhs.svg';
import { ReactComponent as GpaySVG } from '../../assets/gpay.svg';
import { ReactComponent as PercentageSVG } from '../../assets/percentage.svg';
import { ReactComponent as CalendarSvg } from '../../assets/Calendar.svg';
import { ShimmerSimpleGallery } from "react-shimmer-effects";



const Courses = ({ universitiesCount,loading, setSelectedEducationType, selectedEducationType, setEducationTypeCounts, educationTypeCounts, searchQuery, marks, feesFrom, feesTo, courses, count, previousPage, nextPage, countryData, fetchCourses, courseLoading }) => {
    const { setSelectedCourse } = useDropdown();
    // const [courses, setCourses] = useState([]);
    // const [nextPage, setNextPage] = useState(null);
    // const [previousPage, setPreviousPage] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedTab, setSelectedTab] = useState(null);
    const [selectedAgencyName, setSelectedAgencyName] = useState(null)
    const [courseDetails, setCourseDetails] = useState(null);
    const [isAgencyFilterVisible, setIsAgencyFilterVisible] = useState(false);
    const [selectedCourseId, setSelectedCourseId] = useState(null);
    const [educationName, setEducationName] = useState('');
    const [cardCount, setCardCount] = useState(0);
    useEffect(() => {

        const selectedEducationType = JSON.parse(localStorage.getItem('selectedEducationType'));
        if (selectedEducationType && selectedEducationType.name) {

            if (selectedEducationType.name === 'Masters')
                setEducationName('Bachelors');
            else if (selectedEducationType.name === 'Bachelors')
                setEducationName('+2');
            else if (selectedEducationType.name === 'Diploma')
                setEducationName('+2');
            else if (selectedEducationType.name === 'PHD/masters')
                setEducationName('Masters');

        }
    }, [selectedEducationType]);

    const navigate = useNavigate();
    const fetchCourseDetails = async (id) => {
        try {
            const response = await axiosInstance.get(`home/api/v1/student-course-detail/${id}/`);
            setCourseDetails(response.data);
        } catch (err) {
            console.error("Error fetching course details:", err.message);
        }
    };


    const handleNextPage = () => {
        if (nextPage) {
            fetchCourses(nextPage);
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (previousPage) {
            fetchCourses(previousPage);
            setCurrentPage(currentPage - 1);
        }
    };
    const handleTabChange = (tab) => {
        setSelectedTab(tab);
        if (tab === 'courses') {
            navigate(`/course?countryId=${countryId}`);
        } else if (tab === 'university') {
            navigate(`/universities?countryId=${countryId}`);
        }
    };
    // const handleCourseClick = (course) => {
    //     navigate(`/coursedetailsview/${course.id}`, { state: { course, countryData } });
    // };
    const handleCourseClick = (course) => {
        if (selectedCourseId === course.id) {
            setSelectedCourseId(null); // Hide the slide if the same course is clicked again
        } else {
            setSelectedCourseId(course.id); // Show the slide for the clicked course
            fetchCourseDetails(course.id);
        }
    };
    // const countryId = countryData?.id
    const location = useLocation();
    const countryId = new URLSearchParams(location.search).get('countryId');
    const handleContactAgency = (agencyFirstName, courseId) => {
        setSelectedAgencyName(agencyFirstName); // Store agency name
        // setIsAgencyFilterVisible(true); 
        const queryParams = new URLSearchParams({
            countryId,
            marks,
            feesTo
        }).toString();

        navigate(`/admission?courseId=${courseId}&${queryParams}`, {
            state: {
                countryId,

                marks,

                feesTo
            }
        });
        // navigate(`/admission?courseId=${courseId}`); 
    };

    const closeModal = () => {
        setIsAgencyFilterVisible(false); // Close the modal
    };
    const formatExpiryDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleString('default', { month: 'long', year: 'numeric' });
    };

    
 const calculateCardCount = () => {
        const width = window.innerWidth;
        if (width >= 1024) {
            // lg screens
            return 3 * 3; // 3 rows x 3 columns
        } else if (width >= 768) {
            // md screens
            return 2 * 3; // 2 rows x 3 columns
        } else {
            // mobile screens
            return 1 * 3; // 1 row x 3 columns
        }
    };

    useEffect(() => {
        setCardCount(calculateCardCount());

        // Update card count on window resize
        const handleResize = () => {
            setCardCount(calculateCardCount());
        };
        window.addEventListener('resize', handleResize);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


if (courseLoading) {
        return (
            <div className='md:px-16'>
                <div className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4 px-10'>
                    {Array.from({ length: cardCount }).map((_, index) => (
                        <div key={index}
                        >
                            <ShimmerSimpleGallery row={1} col={1} card imageHeight={120} caption />



                        </div>
                    ))}
                </div>
                {courses.length > 0 &&
                                <div className='flex gap-2 py-6 items-center justify-center'>
                                    {previousPage && (
                                        <button
                                            onClick={handlePreviousPage}
                                            disabled={!previousPage}
                                            className={`text-[10px] text-black bg-green-500 px-4 py-2 rounded-full pt-sans-bold ${!previousPage ? 'opacity-50 cursor-not-allowed' : ''}`}
                                        >
                                            Back
                                        </button>
                                    )}


                                    <div className='text-black text-[12px] pt-sans-bold'>{currentPage}</div>
                                    {nextPage && (
                                        <button
                                            onClick={handleNextPage}
                                            disabled={!nextPage}
                                            className={`text-[10px] text-black bg-green-500 px-4 py-2 rounded-full pt-sans-bold ${!nextPage ? 'opacity-50 cursor-not-allowed' : ''}`}
                                        >
                                            Next
                                        </button>

                                    )}


                                </div>
                            }
            </div>
        );
    }



    return (
        <div>
            <div>
                <div className='px-0 md:px-16'>
                    <div className='flex  md:gap-6   flex-col lg:flex-row'>
                        <div className='flex flex-col '>
                            {/* <div className='hidden md:block'>
                                <CourseFilter setSelectedEducationType={setSelectedEducationType} selectedEducationType={selectedEducationType} setEducationTypeCounts={setEducationTypeCounts} educationTypeCounts={educationTypeCounts} />
                            </div> */}

                            <div className='px-4 md:px-0'>
                                <Filters marks={marks}
                                    feesFrom={feesFrom}
                                    feesTo={feesTo}
                                    fetchCourses={fetchCourses}
                                    countryId={countryId} />
                            </div>

                        </div>

                        <div className='flex flex-col gap-6  w-full  px-4 md:px-0'>
                            {/* <FilterTab marks={marks}
                                count={count}
                                universitiesCount={universitiesCount}
                                feesFrom={feesFrom}
                                feesTo={feesTo}
                                setSelectedEducationType={setSelectedEducationType} selectedEducationType={selectedEducationType} setEducationTypeCounts={setEducationTypeCounts} educationTypeCounts={educationTypeCounts} /> */}
                            <div className="grid grid-cols-1 gap-4  sm:grid-cols-1 md:grid-cols-2  ">


                                {courses.length === 0 ? (
                                    <div className="text-center pt-sans-regular text-gray-500 py-4">
                                        No courses available.
                                    </div>
                                ) : (

                                    courses.map(course => (
                                        <div key={course.id} className="bg-white shadow-md cursor-pointer hover:: shadow-lg hover:shadow-lg transition-transform duration-300 ease-in-out transform hover:scale-105 "  >
                                            <div className="flex flex-col justify-between gap-4 px-4 py-4">
                                                <div className="flex flex-col md:flex-row justify-between">

                                                    <div className='w-full'>
                                                        <div style={{

                                                            backgroundImage: `url(${course.university.banner_image ?? '/web2.png'})`,
                                                            backgroundSize: 'cover',
                                                            backgroundPosition: 'center',
                                                            height: '150px',
                                                        }} className='rounded-md relative'>
                                                            <div className="flex  justify-between item-center">
                                                                <div className='flex items-center py-2 px-2 gap-4'>
                                                                    <img src={course.university.logo_image} alt={`${course.university.name} Logo`} className="w-12 h-12 object-cover rounded-full" /> 
                                                                    <div>


                                                                    </div>

                                                                </div>

                                                            </div>



                                                        </div>
                                                        <div className='flex flex-wrap gap-2 pt-2'>

                                                            <div className='pt-sans-bold text-[16px]'>{course.name} - {course.course_duration} Years</div>
                                                            <div className='flex gap-2'>
                                                                <div className=' '>
                                                                    <div className='text-[10px] text-[#007625] bg-green-100 rounded-full px-4 py-1 pt-sans-bold 
                                                             '> Admission open</div>
                                                                </div>
                                                                <div className='bg-green-100 rounded-full flex items-center px-2 gap-1 '>
                                                                    <div><CalendarSvg /></div>
                                                                    <div className='text-[10px] text-[#007625] pt-sans-bold'>
                                                                        {formatExpiryDate(course.expiry_date)}
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className=" pb-2 ">
                                                            <div className="flex gap-1 py-1  overflow-x-auto " style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}>
                                                                {course.tags.map(tag => (
                                                                    <div key={tag.id} className='py-1 flex '>
                                                                        <span className='inter-bold text-[12px] border whitespace-nowrap text-white  bg-[#000000] px-4 rounded-full py-2'>{tag.tag}</span>
                                                                    </div>
                                                                ))}
                                                            </div>


                                                        </div>



                                                        <div className='  flex flex-col   justify-between '>
                                                            <div className='text-[16px] pt-sans-bold text-[#356DDD] pb-1  '>{course.university?.name}</div>
                                                            <div className='pt-sans-regular text-gray-400 text-[14px] '> {course.campus+","} {course.country?.name}</div>
                                                            <div className='pt-sans-regular text-[14px] pt-2'>Admission Requirements</div>
                                                            <div className='w-full overflow-x-auto scrollbar-hide' style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}>
                                                                <div className='flex gap-2 py-2 '>


                                                                    <div className='flex items-center gap-2 py-0.5 px-4  whitespace-nowrap border rounded-full '>
                                                                        <div><PercentageSVG /></div>
                                                                        <div className='pt-sans-bold text-[14px] md:text-[12px]   py-1 '>
                                                                            {parseInt(course.minimum_mark)}% marks Required  in {educationName}
                                                                        </div>
                                                                    </div>
                                                                    <div className='flex items-center gap-2 py-0.5 px-4  whitespace-nowrap border rounded-full'>
                                                                        <div> <GpaySVG /></div>
                                                                        <div className='pt-sans-bold text-[14px] md:text-[12px]  py-1 '><span className='flex gap-1 items-center pt-sans-bold'>
                                                                            {/* Check if language_requirements is an array before mapping */}
                                                                            {Array.isArray(course.language_requirements) && course.language_requirements.length > 0 ? (
                                                                                <>
                                                                                    {course.language_requirements.map((requirement, index) => (
                                                                                        <div className='flex' key={index}>
                                                                                            <span className='pt-sans-bold text-[14px] md:text-[12px] flex whitespace-nowrap'>
                                                                                                {/* Language requirement and score */}
                                                                                                {requirement.language_requirement}
                                                                                                {/* Only display '-' and score if the requirement is not 'MOI' */}
                                                                                                {requirement.language_requirement !== 'MOI' && ` - ${requirement.score}`}
                                                                                                {/* Add '/' unless it is the last item */}
                                                                                                {index < course.language_requirements.length - 1 && ' / '}
                                                                                            </span>
                                                                                        </div>
                                                                                    ))}
                                                                                    {/* Add "required" after the entire list */}
                                                                                    {/* <span className="pt-sans-bold text-[11px] flex"> required</span> */}
                                                                                </>
                                                                            ) : (
                                                                                'N/A' // Fallback if language_requirements is empty or undefined
                                                                            )}
                                                                        </span></div>
                                                                    </div>


                                                                </div>
                                                                <div className='flex gap-2 py-2  '>
                                                                    <div className='flex items-center gap-2 py-0.5 px-4  whitespace-nowrap border rounded-full '>
                                                                        <div><MoneySVG /></div>
                                                                        <div className='pt-sans-bold text-[14px] md:text-[12px]   py-1 '>
                                                                            {course.fee_per_year} Lakhs / Year Fees
                                                                        </div>
                                                                    </div>
                                                                    <div className='flex items-center gap-2 py-0.5 px-4  whitespace-nowrap border rounded-full '>
                                                                        <div> <LakhsSVG /> </div>
                                                                        <div className='pt-sans-bold text-[14px] md:text-[12px]   py-1 '>
                                                                            {course.POF} Lakhs in {course.POF_mode}
                                                                        </div>
                                                                    </div>







                                                                </div>
                                                            </div>







                                                            <div className='bg-green-400 py-2 md:py-0 md:h-8 px-4  rounded-full flex justify-center items-center  gap-1  cursor-pointer  ' onClick={() => handleContactAgency(course.agency?.user_info?.first_name, course.id)} >
                                                                <div><PhoneOutlinedIcon /></div>

                                                                <div className=' text-[15px]   md:text-[13px] whitespace-nowrap  inter-semilight'> Contact to know more</div>
                                                            </div>
                                                            {/* {course.
                                                                is_norvel_recommended &&
                                                                <div className=' hidden md:block pt-sans-bold text-[10px] bg-green-500 rounded-sm px-1'> Recommended</div>} */}
                                                        </div>
                                                        {/* <div class="border-dotted-b   flex items-center justify-center block "></div> */}
                                                        {/* <div className='flex flex-col gap-6 pt-2'>
                                                            <div className='flex gap-4'>
                                                                <div>
                                                                    <div className='pt-sans-regular text-[14px] md:text-[12px]'> Duration</div>
                                                                    <div className='pt-sans-bold text-[14px] md:text-[12px]'>{course.course_duration} Years </div>



                                                                </div>
                                                                <div>
                                                                    <div className='pt-sans-regular text-[14px] md:text-[12px]'>Fees</div>
                                                                    <div className='pt-sans-bold text-[14px] md:text-[12px]'>INR {course.fee_per_year}LPA </div>
                                                                </div>
                                                                <div>
                                                                    <div className='pt-sans-regular text-[14px] md:text-[12px]'>POF</div>
                                                                    <div className='pt-sans-bold text-[14px] md:text-[12px]  '>{course.POF}Lakhs ({course.POF_mode === "Credit Card Limit"
                                                                        ? "Credit Card"
                                                                        : course.POF_mode}
                                                                        ) </div>
                                                                </div>



                                                            </div>





                                                            <div className='flex gap-4'>
                                                                <div>
                                                                    <div className='pt-sans-regular text-[14px] md:text-[12px]'>Intake</div>
                                                                    <div className='pt-sans-bold text-[14px] md:text-[12px]'> {course.intake} </div>
                                                                </div>

                                                                <div>
                                                                    <div className='pt-sans-regular text-[14px] md:text-[12px]'>Language</div>
                                                                    <div className='pt-sans-bold text-[14px] md:text-[12px]'><span className='flex gap-1 items-center pt-sans-bold'>
                                                                       
                                                                        {Array.isArray(course.language_requirements) && course.language_requirements.length > 0 ? (
                                                                            <>
                                                                                {course.language_requirements.map((requirement, index) => (
                                                                                    <div className='flex' key={index}>
                                                                                        <span className='pt-sans-bold text-[14px] md:text-[12px] flex whitespace-nowrap'>
                                                                                            
                                                                                            {requirement.language_requirement}
                                                                                          
                                                                                            {requirement.language_requirement !== 'MOI' && ` - ${requirement.score}`}
                                                                                           
                                                                                            {index < course.language_requirements.length - 1 && ' / '}
                                                                                        </span>
                                                                                    </div>
                                                                                ))}
                                                                                
                                                                            </>
                                                                        ) : (
                                                                            'N/A' 
                                                                        )}
                                                                    </span></div>
                                                                </div>

                                                            </div></div> */}








                                                    </div>
                                                    {/* <div className='pt-4   md:block  hidden'>
                                                        <div>
                                                            <div className='pt-sans-bold text-[11px]'>Living Expense : <span className='pt-sans-regular'>{course.POF}Lakhs* / Year</span> </div>
                                                            <div className='text-[10px]'>{course.POF_mode}</div>
                                                        </div>
                                                    </div> */}
                                                </div>
                                                {/* <div class="border-dotted-b   flex items-center justify-center block md:hidden"></div> */}
                                                {/* <div className='hidden md:block'>
                                                    <div className="flex  justify-between item-center">
                                                        <div className='flex items-center gap-4'>
                                                            <img src={course.university.logo_image} alt={`${course.university.name} Logo`} className="w-12 h-12 object-cover rounded-full" /> 
                                                            <div>
                                                                <div className='text-[12px] pt-sans-bold '>{course.university?.name}</div>
                                                                <div className=' hidden md:flex gap-2 ' >

                                                                    <div className='text-[12px]'><span className='pt-sans-bold'>private</span></div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className='hidden md:block'>
                                                            <div className="flex gap-1 hidden items-center md:block">
                                                                <div className='pt-sans-bold text-[12px]'>{course.campus} , {course.country?.name}</div>
                                                                <img src={course.country.flag} alt={`${course.country.name} Logo`} className="w-8 h-8 object-contain rounded-full" /> 

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}

                                                {/* <div className="flex justify-between ">
                                                    <div className="flex gap-2.5 py-1 flex-wrap">
                                                        {course.tags.map(tag => (
                                                            <div key={tag.id} className='py-1'>
                                                                <span className='inter-bold text-[12px] border  border-black px-4 rounded-full py-2'>{tag.tag}</span>
                                                            </div>
                                                        ))}
                                                    </div>


                                                </div> */}







                                                {/* <div

                                                    className=" "
                                                >
                                                    <div className='flex flex-col md:flex-row justify-between gap-10 bg-white px-4'>



                                                        <div className='md:w-full  '>
                                                            <div className=''>
                                                                <span className='pt-sans-regular  text-[11px] '>For Admission and Enquiry</span></div>

                                                            <div className='flex flex-row items-center  justify-between  '>
                                                                <div className='flex gap-2 items-center py-4'>
                                                                    <div>
                                                                        <img
                                                                            src={courses?.agency?.profile_img}
                                                                            alt={`${course?.agency?.user_info?.first_name}'s Logo`}
                                                                            className="w-11 h-11 object-cover rounded-full" 
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <div className='inter-bold text-[12px]'>  {course?.agency?.user_info?.first_name}</div>
                                                                        <div className='text-[12px] pt-sans-regular'>Location : <span className='inter-bold text-[10px] '>{course?.agency?.location}</span></div>
                                                                    </div>
                                                                </div>
                                                                <div className='flex  pb-4'>
                                                                    <div className='bg-green-400 h-6 px-4  rounded-full flex justify-center items-center  gap-1  cursor-pointer  ' onClick={handleContactAgency} >

                                                                        <div className='text-[11px] whitespace-nowrap  inter-semilight'> Contact Agency</div>
                                                                    </div>
                                                                </div>



                                                            </div>



                                                        </div>
                                                    </div>
                                                </div>  */}
                                            </div>





                                        </div>

                                    ))

                                )}


                            </div>


                            {courses.length > 0 &&
                                <div className='flex gap-2 py-6 items-center justify-center'>
                                    {previousPage && (
                                        <button
                                            onClick={handlePreviousPage}
                                            disabled={!previousPage}
                                            className={`text-[10px] text-black bg-green-500 px-4 py-2 rounded-full pt-sans-bold ${!previousPage ? 'opacity-50 cursor-not-allowed' : ''}`}
                                        >
                                            Back
                                        </button>
                                    )}


                                    <div className='text-black text-[12px] pt-sans-bold'>{currentPage}</div>
                                    {nextPage && (
                                        <button
                                            onClick={handleNextPage}
                                            disabled={!nextPage}
                                            className={`text-[10px] text-black bg-green-500 px-4 py-2 rounded-full pt-sans-bold ${!nextPage ? 'opacity-50 cursor-not-allowed' : ''}`}
                                        >
                                            Next
                                        </button>

                                    )}


                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <AnimatePresence>
                {isAgencyFilterVisible && (
                    <AgencyFilter closeModal={closeModal} countryData={countryData} courseId={selectedCourseId} marks={marks} selectedAgencyName={selectedAgencyName}
                        feesFrom={feesFrom}
                        feesTo={feesTo} />
                )}
            </AnimatePresence>

        </div>
    )
}

export default Courses
