import React, { useState, useEffect, useCallback } from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import UniversityDropdown from '../dropDown/UniversityDropdown';
import tokenInstance from '../axiosInstance/tokenInstance';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { useNavigate, useLocation } from 'react-router-dom';
import debounce from 'lodash.debounce';


const Filters = ({ marks,
    feesFrom,
    feesTo,  fetchCourses, fetchUniversities }) => {
    const [formData, setFormData] = useState({
        marks: marks || '',
        // fees_from: feesFrom || 0,
        fees_to: feesTo || '',
        location: "",
        university_type: "",
        benefits: ""
    });

    const [universityStatus, setUniversityStatus] = useState('');
    const [universityOpen, setUniversityOpen] = useState(null);
    const [filterId, setFilterId] = useState(null);
    const [isVisible, setIsVisible] = useState(false);
    const [isSuccessful, setIsSuccessful] = useState(false);
    const navigate = useNavigate();
     const location = useLocation();
        const countryId = new URLSearchParams(location.search).get('countryId');


    useEffect(() => {
        // Update formData with incoming marks, feesFrom, and feesTo values if provided
        setFormData(prevData => ({
            ...prevData,
            marks: marks ?? prevData.marks,
            // fees_from: feesFrom ?? prevData.fees_from,
            fees_to: feesTo ?? prevData.fees_to
        }));
    }, [marks,  feesTo]);

    useEffect(() => {
        const fetchFilterData = async () => {
            try {
                const response = await tokenInstance.get('home/api/v1/view-student-filter/');
                if (response.status === 200) {
                    const filterData = response.data;
                    setFilterId(filterData.id);
                    setFormData({
                        marks: filterData.marks,
                        // fees_from: filterData.fees_from,
                        fees_to: filterData.fees_to,
                        location: filterData.location || "",
                        university_type: filterData.university_type || "",
                        benefits: filterData.benefits || ""
                    });
                    setIsSuccessful(true);
                    setUniversityStatus(filterData.university_type || "");
                }
            } catch (error) {
                console.error('Error fetching filter data:', error);
                setIsSuccessful(false);
            }
        };
        // Check if 'user_info' exists in local storage before calling fetchFilterData
        const userInfo = localStorage.getItem('user_info');
        if (userInfo) {
            fetchFilterData();
        }
    }, []);
    const updateURLParams = (newMarks, newFeesTo) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('countryId', countryId);
        searchParams.set('marks', newMarks);
        // searchParams.set('feesFrom', newFeesFrom);
        searchParams.set('feesTo', newFeesTo);
        navigate(`${location.pathname}?${searchParams.toString()}`);
    };

    const debouncedUpdate = useCallback(
        debounce((newMarks, newFeesTo) => {
            updateURLParams(newMarks,  newFeesTo);
            handleFetchData(newMarks,  newFeesTo);
        }, 300),
        [countryId, location.pathname] // Dependencies for the debounce function
    );

    const handleMarksChange = (event, newValue) => {
        // setFormData({ ...formData, marks: newValue });
        setFormData((prevData) => ({ ...prevData, marks: newValue }));
        debouncedUpdate(newValue,  formData.fees_to);
    };

    // const handleFeesChange = (event, newValue) => {
    //     setFormData({ ...formData, fees_from: newValue[0], fees_to: newValue[1] });
    //     debouncedUpdate(formData.marks, newValue[0], newValue[1]);
    // };
    const handleFeesChange = (event, newValue) => {
       
        setFormData((prevData) => ({ ...prevData, fees_to: newValue }));
        debouncedUpdate(formData.marks, newValue);
    };

    

    const handleFetchData = (marks,  fees_to) => {
        if (location.pathname.includes('/universities')) {
            fetchUniversities(`home/api/v1/student-university-list/?country=${countryId}&limit=4&marks=${marks}&fees_to=${fees_to}`);
        } else if (location.pathname.includes('/course')) {
            fetchCourses(`home/api/v1/student-course-list/?country=${countryId}&limit=4&marks=${marks}&fees_to=${fees_to}`);
        }
    };

    const UniversityMethod = (option) => {
        setUniversityStatus(option);
        setFormData({ ...formData, university_type: option });
    };

    const toggleUniversity = () => {
        setUniversityOpen((prevState) => !prevState);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSaveFilter = async () => {
        if (!filterId) {
            console.error('Filter ID not found');
            return;
        }

        const payload = {
            marks: formData.marks,
           
            fees_to: formData.fees_to,
            location: formData.location,
            university_type: formData.university_type,
            benefits: formData.benefits,
            education_level: formData.education_level
        };

        try {
            const response = await tokenInstance.patch(`home/api/v1/update-student-filter/${filterId}/`, payload);
            console.log('Filter updated:', response.data);
        } catch (error) {
            console.error('Error updating filter:', error);
        }
    };

    const formatFees = (value) => {
        return `${value} LPA`;
    };

    const toggleMainPartVisibility = () => {
        setIsVisible((prev) => !prev);
    };

    return (
        <div>
            {/* <div className='md:hidden '>
                <div className=' bg-white   border border-gray-100 rounded-sm py-2 px-4 shadow-md  flex justify-between items-center' onClick={toggleMainPartVisibility}>
                    {isSuccessful && formData.marks !== null ? (
                        <div className='flex justify-between w-full px-4'>
                            <div className='pt-sans-regular text-[12px]'>Your Marks in Class {formData.education_level && `  ${formData.education_level}`}</div>
                            <div className='pt-sans-regular text-[12px]'>{formData.marks}%</div>
                        </div>
                    ) : (
                        <div className='pt-sans-bold text-[12px]'>Filter</div>
                    )}
                    <div>{isVisible ? <FiChevronUp /> : <FiChevronDown />} </div>
                </div>

                <div className={`main-part bg-white shadow-md px-4  w-full transition-all duration-300 ${isVisible ? 'slide-down' : 'slide-up'}`}>
                    <div className='pb-4 py-6'>
                        <div>
                            <div className='text-[13px] pt-sans-bold pb-2'>Your Marks in 12th </div>
                        </div>
                        <Box>
                            <Slider
                                defaultValue={formData.marks}
                                aria-label="Marks"
                                valueLabelDisplay="auto"
                                sx={{
                                    color: 'black',
                                    '& .MuiSlider-thumb': {
                                        borderRadius: '50%',
                                        backgroundColor: 'black',
                                        border: '2px solid black',
                                    },
                                    '& .MuiSlider-track': {
                                        backgroundColor: 'black',
                                    },
                                    '& .MuiSlider-rail': {
                                        backgroundColor: '#bfbfbf',
                                    },
                                    '& .MuiSlider-valueLabel': {
                                        backgroundColor: 'black',
                                        color: 'white',
                                        borderRadius: '4px',
                                    },
                                }}
                                onChange={handleMarksChange}
                                value={formData.marks}
                            />
                        </Box>
                        <div className='flex justify-between '>
                            <div className='pt-sans-regular text-[12px]'>0%</div>
                            <div className='pt-sans-bold text-[12px]'>{formData.marks}%</div>
                            <div className='pt-sans-regular text-[12px]'>100%</div>
                        </div>
                    </div>

                    <div className='py-4'>
                        <div className='text-[13px] pt-sans-bold pb-2'>Yearly fees you can afford (INR)</div>
                        <Box>
                            <Slider
                                value={ formData.fees_to}
                                onChange={handleFeesChange}
                                valueLabelDisplay="auto"
                                min={0}
                                max={50}
                                sx={{
                                    color: 'black',
                                    '& .MuiSlider-thumb': {
                                        borderRadius: '50%',
                                        backgroundColor: 'black',
                                    },
                                    '& .MuiSlider-track': {
                                        backgroundColor: 'black',
                                    },
                                    '& .MuiSlider-rail': {
                                        backgroundColor: 'black',
                                    },
                                    '& .MuiSlider-valueLabel': {
                                        backgroundColor: 'black',
                                        color: 'white',
                                        borderRadius: '4px',
                                    },
                                }}
                            />
                        </Box>
                        <div className='flex justify-between '>
                            <div className='pt-sans-regular text-[12px]'>0 LPA</div>
                            <div className='pt-sans-bold text-[12px]'>{`${formatFees(formData.fees_to)}`}</div>
                            <div className='pt-sans-regular text-[12px]'>50 LPA</div>
                        </div>
                    </div>

                    <div className='flex flex-col gap-2'>
                        <div>
                            <input
                                type="text"
                                name="location"
                                placeholder="Location"
                                className="border border-gray-300 rounded-md p-2 w-full text-[12px] text-black outline-none px-4"
                                value={formData.location}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div>
                            <UniversityDropdown
                                options={['Private', 'Public']}
                                selectedOption={universityStatus}
                                onSelect={UniversityMethod}
                                toggleDropdown={toggleUniversity}
                                universityOpen={universityOpen}
                                setUniversityOpen={setUniversityOpen}
                            />
                        </div>
                        <div>
                            <input
                                type="text"
                                name="benefits"
                                placeholder="Benefits"
                                className="border border-gray-300 rounded-md p-2 w-full text-[12px] px-4 outline-none"
                                value={formData.benefits}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>

                    <div className='flex justify-end py-4'>
                        <button
                            onClick={handleSaveFilter}
                            className='bg-green-500 px-4  text-[12px] pt-sans-bold rounded-full text-center py-2 '
                        >
                            Save Filter
                        </button>
                    </div>
                </div>
            </div> */}


            <div className='hidden md:block pt-2'>



                <div className='main-part   bg-[#101828] shadow-md px-4 py-6 w-[400px] '>
                    <div className='pb-4'>
                        <div>
                            <div className='text-[13px] text-white pt-sans-bold pb-2'>Your Mark </div>
                        </div>

                        <Box  >
                            <Slider
                                defaultValue={formData.marks}
                                aria-label="Marks"
                                valueLabelDisplay="auto"
                                sx={{
                                    color: 'black',
                                    '& .MuiSlider-thumb': {
                                        borderRadius: '50%',
                                        backgroundColor: 'white',
                                        border: '2px solid black',
                                    },
                                    '& .MuiSlider-track': {
                                        backgroundColor: 'green',
                                    },
                                    '& .MuiSlider-rail': {
                                        backgroundColor: 'white',
                                    },
                                    '& .MuiSlider-valueLabel': {
                                        backgroundColor: 'white',
                                        color: 'black',
                                        borderRadius: '4px',
                                    },
                                }}
                                onChange={handleMarksChange}
                                value={formData.marks}
                            />
                        </Box>

                        <div className='flex justify-between '>
                            <div className='pt-sans-regular text-[12px] text-white'>0%</div>
                          {formData.marks && (<div className='pt-sans-bold text-[12px] text-white'>{formData.marks}%</div>)}  
                            <div className='pt-sans-regular text-[12px] text-white'>100%</div>
                        </div>
                    </div>

                    <div className='py-4'>
                        <div className='text-[13px] pt-sans-bold pb-2 text-white'>Yearly fees you can afford (INR)</div>
                        <Box>
                            <Slider
                                value={ formData.fees_to}
                                onChange={handleFeesChange}
                                valueLabelDisplay="auto"
                                min={0}
                                max={50}
                                sx={{
                                    color: 'black',
                                    '& .MuiSlider-thumb': {
                                        borderRadius: '50%',
                                        backgroundColor: 'white',
                                        border: '2px solid black',
                                    },
                                    '& .MuiSlider-track': {
                                        backgroundColor: 'green',
                                    },
                                    '& .MuiSlider-rail': {
                                        backgroundColor: 'white',
                                    },
                                    '& .MuiSlider-valueLabel': {
                                        backgroundColor: 'white',
                                        color: 'black',
                                        borderRadius: '4px',
                                    },
                                }}
                            />
                        </Box>
                        <div className='flex justify-between '>
                            <div className='pt-sans-regular text-[12px] text-white'>0 LPA</div>
                          {formData.fees_to && (<div className='pt-sans-bold text-[12px] text-white'>{` ${formatFees(formData.fees_to)}`}</div>)}  
                            <div className='pt-sans-regular text-[12px] text-white'>50 LPA</div>
                        </div>
                    </div>

                    <div className='flex flex-col gap-2'>
                        <div>
                            <input
                                type="text"
                                name="location"
                                placeholder="Location"
                                className="border border-gray-300 rounded-md p-2 w-full text-[12px] text-black outline-none px-4"
                                value={formData.location}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div>
                            <UniversityDropdown
                                options={['Private', 'Public']}
                                selectedOption={universityStatus}
                                onSelect={UniversityMethod}
                                toggleDropdown={toggleUniversity}
                                universityOpen={universityOpen}
                                setUniversityOpen={setUniversityOpen}
                            />
                        </div>
                        <div>
                            <input
                                type="text"
                                name="benefits"
                                placeholder="Benefits"
                                className="border border-gray-300 rounded-md p-2 w-full text-[12px] px-4 outline-none"
                                value={formData.benefits}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>

                    <div className='flex justify-end pt-4'>
                        <button
                            onClick={handleSaveFilter}
                            className='bg-green-500  px-4 text-[13px] pt-sans-bold rounded-full text-center py-2 '
                        >
                            Save Filter
                        </button>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default Filters;




