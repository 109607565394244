import React, { useState, useEffect } from 'react'
import axiosInstance from '../components/axiosInstance/axiosInstance'
import tokenInstance from '../components/axiosInstance/tokenInstance'
import { useNavigate } from 'react-router'
import CallIcon from '@mui/icons-material/Call';
import { useMentorContext } from './MentorContext';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { Search } from "@mui/icons-material"; // Material-UI search icon

const MentorList = () => {
    const [studentId, setStudentId] = useState(null)
    const [socket, setSocket] = useState(null);
    const navigate = useNavigate()
    const [requestList, setRequestList] = useState([]);
    const [countries, setContries] = useState([])
    const [searchQuery, setSearchQuery] = useState(""); // Search by university name
    const [selectedCountry, setSelectedCountry] = useState(""); // Filter by country

    const {
        mentorList,
        fetchMentorList,
        setRoomID,
        handleCall,
    } = useMentorContext();

    useEffect(() => {
        fetchMentorList({ search: searchQuery, country: selectedCountry });
    }, [searchQuery, selectedCountry]);

    useEffect(() => {
        tokenInstance.get('kanban/api/v1/country_dropdown/?search=&limit=10')
            .then(response => {
                console.log("Fetched Countries:", response.data.results);
                setContries(response.data.results);
            })
            .catch(error => console.error('Error fetching countries:', error));
    }, []);

    useEffect(() => {
        const ws = new WebSocket('wss://norvelbackend.site/ws/mentor_list_refresh/mentors/');
        setSocket(ws);

        ws.onmessage = (event) => {
            console.log('WebSocket message received:', event.data);
            fetchMentorList();
        };

        return () => {
            if (ws) {
                ws.close();
                console.log('WebSocket connection closed');
            }
        };
    }, []);

    const handleNotifyMe = async (mentorId) => {
        try {
            const response = await tokenInstance.post("calls/api/v1/notify_student_request_create/", {
                mentor: mentorId,
            });

            if (response.status === 201) {
                alert("Notification request sent successfully!");
            }
        } catch (error) {
            console.error("Error sending notification request:", error);
            alert("Failed to send notification.");
        }
    };

    return (
        <div className='px-6 py-4'>
            <div >
                <div className='text-[14px] pt-sans-bold pt-2'>Popular Countries</div>
                <div className="flex gap-2 py-2 overflow-x-auto" style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}>
                    {countries.map((country, index) => (
                        <div
                            key={country.id}
                            className={`text-[12px] pt-sans-bold px-4 py-1 border rounded-full cursor-pointer 
                             ${selectedCountry === country.id ? "bg-blue-500 text-white" : ""}`}
                            onClick={() => setSelectedCountry(country.id)}
                        >
                            {country.name}
                        </div>
                    ))}
                </div>
            </div>

            <div className="relative  w-full  py-2">
                <input
                    type="search"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="Search by University"
                    className="w-full py-2.5 pl-10 pr-4 text-sm border border-gray-500 rounded-full outline-none"
                />
                <Search className="absolute text-black left-3 top-1/2 transform -translate-y-1/2" />
            </div>

            <div className='py-4'>
                <div className='text-[14px] pt-sans-bold pb-1'>Talk with our Mentors</div>
                <div className='text-[12px] pt-sans-regular'>More than 500+ registered mentors worldwide</div>
            </div>

            <div className='grid grid-cols-1 md:grid-cols-3 gap-6'>
                {mentorList.length > 0 ? (
                    mentorList.map((mentor) => (
                        <div key={mentor.id}>
                            <div className='shadow-md'>
                                <div className='flex flex-row items-center gap-4 py-4 px-2 rounded-md '>
                                    <div className='w-[160px] h-[200px]'>
                                        <img className="w-[100%] h-[100%] object-cover rounded-md"
                                            src="./img4.jpg"
                                            alt="Mentor"

                                        />
                                    </div>
                                    <div className='flex flex-col gap-2 mt-4'>
                                        <div className='flex gap-2 items-center'>
                                            <div className='text-[15px] pt-sans-bold'>
                                                {mentor.first_name} {mentor.last_name}
                                            </div>
                                            <div className='w-6 h-6 rounded-full'>
                                                <img className='w-[100%] object-cover  h-[100%]' src={mentor.country?.flag} />
                                            </div>
                                        </div>
                                        <div className='text-[12px] pt-sans-bold text-blue-500'>
                                            {mentor.course?.university?.name}
                                            <span className='pt-sans-regular text-[12px] text-black'> Dublin,</span>
                                            <span className='text-black text-[12px] pt-sans-bold'> Ireland</span>
                                        </div>
                                        <div>
                                            <div className='text-[12px] text-gray-500 pt-sans-regular'>
                                                {mentor.course?.name}
                                            </div>
                                            <div className='py-2 flex gap-2  overflow-x-auto w-full ' style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}>
                                                {mentor.mentor.language?.map((lang, index) => (

                                                    <div key={index}>
                                                        <div className='text-[12px] pt-sans-bold bg-blue-200 px-4 py-1 rounded-full'>
                                                            {lang.code}
                                                        </div>
                                                    </div>

                                                ))}
                                            </div>


                                        </div>
                                    </div>
                                </div>

                                <div className="py-4  px-4 w-full gap-2 flex justify-between">
                                    <div className='text-[12px] pt-sans-bold rounded-full py-2 px-4 border border-green-600 w-full flex justify-center items-center'>See Profile</div>
                                    {mentor.room ? (
                                        <div className='text-[12px] pt-sans-bold bg-green-500 rounded-full py-2 px-4 border border-green-600 w-full flex justify-center items-center'>
                                            <div className='flex gap-2 justify-center items-center' onClick={() => handleCall(mentor.room)}>
                                                <div><CallIcon style={{ fontSize: '18px' }} /></div>
                                                <div className='text-[12px] pt-sans-bold cursor-pointer '>Call Now</div>
                                            </div>
                                        </div>) : (
                                        <div
                                            className='text-[12px] pt-sans-bold  rounded-full py-2 px-4 border border-green-600 w-full flex justify-center items-center'
                                            onClick={() => handleNotifyMe(mentor.mentor_id)}
                                        >
                                            <NotificationsActiveIcon style={{ fontSize: '20px' }} />
                                            <span className='ml-1 cursor-pointer'>Notify me</span>
                                        </div>

                                    )}
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className='pt-sans-bold ' >Loading mentors...</div>
                )}
            </div>
        </div>
    )
}

export default MentorList;
