import React, { useState, useEffect, useRef } from 'react';
import tokenInstance from '../components/axiosInstance/tokenInstance';
import CountryDropDown from './DropDown/CountryDropdown';
import MultiSelectDropdown from './DropDown/MultiSelectDropdown';
import UniversityDropDown from './DropDown/UniversityDropdown';
import CourseDropDown from './DropDown/CourseDropdown';
import ImageIcon from '@mui/icons-material/Image';

const MentorRegistration = () => {
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        country: '',
        language: [],
        image: '',
        university: '',
        course: '',
    });

    const [countries, setCountries] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [universities, setUniversities] = useState([]);
    const [courses, setCourses] = useState([]);
    const [fileName, setFileName] = useState('Upload File');
    const fileInputRef = useRef(null);


    useEffect(() => {

        tokenInstance.get('account/api/v1/mentor-languages/')
            .then(response => setLanguages(response.data))
            .catch(error => console.error('Error fetching languages:', error));
    }, []);

    useEffect(() => {
        tokenInstance.get('kanban/api/v1/country_dropdown/?search=&limit=10')
            .then(response => {
                console.log("Fetched Countries:", response.data.results);
                setCountries(response.data.results);
            })
            .catch(error => console.error('Error fetching countries:', error));
    }, []);


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    // const handleCountryChange = (countryId) => {
    //     setFormData(prev => ({ ...prev, country: countryId }));
    // };
    const handleCountryChange = (countryId) => {
        setFormData(prev => ({ ...prev, country: countryId, university: '', course: '' }));


        tokenInstance.get(`home/api/v1/university-dropdown/?country=${countryId}`)
            .then(response => {
                setUniversities(response.data);
                setCourses([]);
            })
            .catch(error => console.error('Error fetching universities:', error));
    };

    const handleUniversityChange = (universityId) => {
        setFormData(prev => ({ ...prev, university: universityId, course: '' }));

        // Fetch courses based on selected university
        tokenInstance.get(`home/api/v1/student-course-dropdown/?university=${universityId}`)
            .then(response => {
                setCourses(response.data);
            })
            .catch(error => console.error('Error fetching courses:', error));
    };

    const handleCourseChange = (courseId) => {
        setFormData(prev => ({ ...prev, course: courseId }));
    };












    const handleLanguageChange = (selectedLanguages) => {
        setFormData(prev => ({ ...prev, language: selectedLanguages }));
    };

    const handleImageUpload = async (e) => {
        const file = e.target.files[0];
        if (!file) return;

        setUploading(true);
        setFileName(file.name);

        const uploadData = new FormData();
        uploadData.append('file', file);

        try {
            const response = await tokenInstance.post('home/api/v1/upload-file/', uploadData);
            setFormData(prev => ({
                ...prev, image: response.data.
                    image_url
            }));
        } catch (error) {
            console.error('Error uploading image:', error);
        } finally {
            setUploading(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const { university, ...filteredData } = formData;

        try {
            await tokenInstance.post('account/api/v1/register-mentor-user/', filteredData);
            alert('Mentor registered successfully!');
            setFormData({ first_name: '', last_name: '', email: '', country: '', language: [], image: '', course: '' });
        } catch (error) {
            // Handle error response and set error message
            if (error.response && error.response.data) {
                const { error: errorMsg } = error.response.data;
                setErrorMessage(errorMsg); // Set the error message from response
            } else {
                setErrorMessage('An unexpected error occurred.');
            }
            console.error('Error submitting form:', error);
        }
    };

    return (
        <div className="p-4">
            <h2 className="text-xl pt-sans-bold mb-4 bg-black text-white py-2 px-2 rounded-md text-center">Mentor Registration</h2>
            <form onSubmit={handleSubmit} className="space-y-3">
                <div>
                    <label className='pt-sans-regular text-[14px]'>First Name:</label>
                    <input type="text" name="first_name" value={formData.first_name} onChange={handleChange} className="border p-2 w-full rounded-md" />
                </div>
                <div>
                    <label className='pt-sans-regular text-[14px]'>Last Name:</label>
                    <input type="text" name="last_name" value={formData.last_name} onChange={handleChange} className="border p-2 w-full rounded-md" />
                </div>
                <div>
                    <label className='pt-sans-regular text-[14px]'>Email:</label>
                    <input type="email" name="email" value={formData.email} onChange={handleChange} className="border p-2 w-full rounded-md" />
                </div>
                <div>
                    <label className='pt-sans-regular text-[14px]'>Country:</label>
                    <CountryDropDown options={countries} value={formData.country} onChange={handleCountryChange} />
                </div>
                <div>
                    <label className='pt-sans-regular text-[14px]'>University:</label>
                    <UniversityDropDown options={universities} value={formData.university} onChange={handleUniversityChange} />
                </div>
                <div>
                    <label className='pt-sans-regular text-[14px]'>Course:</label>
                    <CourseDropDown options={courses} value={formData.course} onChange={handleCourseChange} />
                </div>
                <div>
                    <label className='pt-sans-regular text-[14px]'>Language:</label>
                    <MultiSelectDropdown options={languages} selectedValues={formData.language} onChange={handleLanguageChange} />
                </div>
                <div>
                    <label className='pt-sans-regular text-[14px]'>Upload Image:</label>
                    <div className="relative flex items-center border border-dotted border-2  gap-4">
                        {/* Hidden File Input */}
                        <input
                            type="file"
                            accept="image/*"
                            ref={fileInputRef}
                            onChange={handleImageUpload}
                            className="hidden"
                        />

                        {/* Image Preview inside Input Box */}
                        <div
                            className="border flex items-center justify-center  w-full rounded-md  h-[150px] flex items-center gap-2 cursor-pointer"
                            onClick={() => fileInputRef.current.click()}
                        >
                            {formData.image ? (

                                <img src={formData.image} alt="Uploaded" className="w-[100%] h-[100%] rounded-md object-cover" />

                            ) : (
                               
                                <span className="text-gray-500 pt-sans-regular ext-[12px] "><ImageIcon style={{fontSize:"30px"}}/> Upload Image Here</span>
                                
                            )}
                        </div>
                    </div>

                    {uploading && <p className="text-blue-500">Uploading...</p>}
                </div>


                <div className='flex justify-end items-end w-full'>
                    <button type="submit" className="bg-black  w-full rounded-md text-white pt-sans-bold   px-4 py-2">Submit</button>
                </div>
                {errorMessage && <p className="text-red-500 mt-2">{errorMessage}</p>}
            </form>
        </div>
    );
};

export default MentorRegistration;







