import React from 'react'
import StudentHeader from './StudentHeader'
import MentorList from './MentorList'
import Bottom from './Bottom'

const StudentPages = () => {
    return (
        <div>
            <StudentHeader />
            <MentorList />
            <Bottom/>
        </div>
    )
}

export default StudentPages