import React, { createContext, useContext, useState } from 'react';
import tokenInstance from '../components/axiosInstance/tokenInstance';
import { useNavigate } from 'react-router';

// Create a context for mentor-related state
const MentorContext = createContext();


// Provider component to wrap the application and share the state
export const MentorProvider = ({ children }) => {
    const [mentorList, setMentorList] = useState([]);
    const [roomId, setRoomID] = useState(null);
     const [callResponse, setCallResponse] = useState([]);
     
 
    
    const [studentId, setStudentId] = useState(null);
    const [requestList, setRequestList] = useState([]);
    const [nextPage, setNextPage] = useState(null);
    const [offset, setOffset] = useState(0);
    const [loading, setLoading] = useState(false); // Prevent duplicate fetches
    const navigate= useNavigate()


      const handleCall = async (roomId) => {
            if (!roomId) {
                console.error("Room ID is not set.");
                return;
            }
            try {
                const response = await tokenInstance.post("calls/api/v1/voice-call-room-join-request/", {
                    room: roomId,
                });
             
                setCallResponse(response.data);
    
                setRoomID(response.data.room.id);
                setStudentId(response.data.student.id);
    
    
                if (response.status === 201) {
                    fetchStudentRequestList(response.data.room.id);
                   
    
                    console.log("hello call request list")
                    navigate('/callwaiting', {
                        state: {
                            callResponse: response.data,
    
                        },
                    });
    
    
    
                } else {
                    console.log("Unexpected status code:", response.data);
                }
            } catch (err) {
                console.log("Error posting call API:", err.message);
            }
        };

        const fetchMentorList = async ({ search = "", country = "" } = {}) => {
            try {
                const response = await tokenInstance.get(
                    `calls/api/v1/mentors_list/?search=${search}&country=${country}`
                );
                setMentorList(response.data);
            } catch (error) {
                console.error("Error fetching mentor list:", error);
            }
        };

    // const fetchStudentRequestList = async (roomIdParam) => {
    //     try {
    //         const id = roomIdParam || roomId; // Use the parameter if provided, fallback to state
    //         if (id) {
    //             console.log("Fetching request list for room ID:", id);
    //             const response = await tokenInstance.get(`calls/api/v1/voice-call-room-request-list/?room_id=${id}&limit=4`);
    //             setRequestList(response.data.results);
    //         }
    //     } catch (err) {
    //         console.log('Error fetching API:', err.message);
    //     }
    // };
    const fetchStudentRequestList = async (
        url = `calls/api/v1/voice-call-room-request-list/?room_id=${roomId}&limit=4`
    ) => {
        if (!url || loading) return; // Avoid unnecessary API calls
    
        setLoading(true);
    
        try {
            const response = await tokenInstance.get(url);
    
            setRequestList((prevRequests) => {
                // Use Set to track unique IDs and avoid duplicates
                const existingIds = new Set(prevRequests.map((req) => req.id));
    
                // Add only new requests
                const newRequests = response.data.results.filter(
                    (newRequest) => !existingIds.has(newRequest.id)
                );
    
                return [...prevRequests, ...newRequests];
            });
    
            setNextPage(response.data.next); // Update next page URL (null if no more pages)
        } catch (err) {
            console.error("Error fetching request list:", err.message);
            setNextPage(null); // Set to null on error to prevent infinite fetching
        } finally {
            setLoading(false);
        }
    };
    
    
    
    
    return (
        <MentorContext.Provider
            value={{
                mentorList,
                setMentorList,
                roomId,
                setRoomID,
                callResponse,
                nextPage,
              
                studentId,
                setStudentId,
                requestList,
                setRequestList,
                fetchMentorList,
                fetchStudentRequestList,
                handleCall,
            }}
        >
            {children}
        </MentorContext.Provider>
    );
};

// Custom hook to use the MentorContext
export const useMentorContext = () => useContext(MentorContext);
