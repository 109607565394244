import React, { useState } from 'react';

const CourseDropDown = ({ options, value, onChange }) => {
    const [open, setOpen] = useState(false);

    return (
        <div className="relative">
            <div
                className="border px-2 py-3 cursor-pointer bg-white pt-sans-regular text-[12px]  rounded-md"
                onClick={() => setOpen(!open)}
            >
                {value ? options.find(option => option.id === value)?.name : "Select Course"}
            </div>
            {open && (
                <ul className="absolute z-10 bg-white border mt-1 w-full max-h-40 overflow-y-auto">
                    {options.map(option => (
                        <li
                            key={option.id}
                            className="p-2 cursor-pointer hover:bg-gray-200"
                            onClick={() => {
                                onChange(option.id);
                                setOpen(false);
                            }}
                        >
                            <span className='pt-sans-regular text-[13px]'> {option.name}</span> 
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default CourseDropDown;
