import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import Logout from "../authetication/Logout";
import { useDropdown } from "../DropdownContext/DropdownContext";
import { useLocation, useParams } from "react-router-dom";
import axiosInstance from "../axiosInstance/axiosInstance";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import PersonIcon from "@mui/icons-material/Person";

import SideBar from "../sidebar/SideBar";
import CourseTabs from "../tabs/CourseTabs";

import { useNavigate } from "react-router-dom";

import GroupPNG from "../../assets/Group.png";
import { CLIENT_ID, REDIRECT_URI } from "../../config/config";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const CenterWrapper = styled.div`
  background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
  
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;

  

  @media (min-width: 1441px) {
    height: ${({ backgroundImage, homeBanner }) =>
    backgroundImage === homeBanner
      ? "54vh" // Specific height for homeBanner
      : backgroundImage === "/home.png"
        ? "42vh" // Specific height for /home.png
        : "55vh"}; // Default height for other images
  }

   @media (min-width: 1450px) {
    height: ${({ backgroundImage, homeBanner }) =>
    backgroundImage === homeBanner
      ? "54vh" // Specific height for homeBanner
      : backgroundImage === "/home.png"
        ? "50vh" // Specific height for /home.png
        : "78vh"}; // Default height for other images
  }


  
  @media (max-width: 426px) {
    background-size: cover;
    height: 30vh;

    background-position: top center; /* Adjust the focal point for mobile */
    /* Adjust height for mobile devices */
  }

  @media (max-width: 482px) {
    background-size: cover;
    height: 17vh;

    background-position: top center; /* Adjust the focal point for mobile */
    /* Adjust height for mobile devices */
  }

  @media (max-width: 426px) {
    background-size: cover;
    height: 25vh;

    background-position: top center; /* Adjust the focal point for mobile */
    /* Adjust height for mobile devices */
  }

  @media (max-width: 416px) {
    background-size: cover;

    height: 17vh;

    background-position: top center; /* Adjust the focal point for mobile */
    /* Adjust height for mobile devices */
  }
  @media (max-width: 376px) {
    background-size: cover;
    height:21vh;

    background-position: top center; /* Adjust the focal point for mobile */
    /* Adjust height for mobile devices */
  }
   
  @media (max-width: 365px) {
   
        background-size: cover;
        height: 18vh;
        background-position: top center;
    
}

  @media (max-width: 345px) {
    background-size: cover;
    height: 14vh;

    background-position: top center; /* Adjust the focal point for mobile */
    /* Adjust height for mobile devices */
  }

  //   &:before {
  //   content: '';
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   background: rgba(0, 0, 0, 0.4); /* Black overlay with opacity */
  //   z-index: 1;
  // }
`;

// const CenterWrapper = styled.div`
//   ${({ backgroundImage }) => {
//     if (backgroundImage === '/mobileimage.png' || backgroundImage === '/home.png') {
//       return `
//         background-image: url(${backgroundImage});
//         background-size: cover;
//         background-position: center;
//         background-repeat: no-repeat;
//         width: 100%;
//   position: relative;

//   @media (min-width: 768px) {
//     height: 42vh;
//   }
//   @media (min-width: 1440px) {
//     background-size: cover;
//     height: 41vh;
//   }
//   @media (max-width: 426px) {
//     background-size: cover;
//     height: 30vh;
//     background-position: top center;
//   }
//   @media (max-width: 482px) {
//     background-size: cover;
//     height: 18vh;
//     background-position: top center;
//   }
//   @media (max-width: 416px) {
//     background-size: cover;
//     height: 17vh;
//     background-position: top center;
//   }
//   @media (max-width: 376px) {
//     background-size: cover;
//     height: 19vh;
//     background-position: top center;
//   }
//   @media (max-width: 345px) {
//     background-size: cover;
//     height: 15vh;
//     background-position: top center;
//   }
//       `;
//     } else {
//       return `
//         background-image: url(${backgroundImage});
//         background-size: contain;
//         background-position: center;
//         background-repeat: no-repeat;
//         width: 100%;
//   position: relative;

//   @media (min-width: 768px) {
//     height: 42vh;
//   }
//   @media (min-width: 1440px) {
//     background-size: cover;
//     height: 76vh;
//   }
//   @media (max-width: 426px) {
//     background-size: cover;
//     height: 30vh;
//     background-position: top center;
//   }
//   @media (max-width: 482px) {
//     background-size: cover;
//     height: 18vh;
//     background-position: top center;
//   }
//   @media (max-width: 416px) {
//     background-size: cover;
//     height: 20vh;
//     background-position: top center;
//   }
//     @media (max-width: 360px) {
//     background-size: cover;
//     height: 17vh;
//     background-position: top center;

//   @media (max-width: 376px) {
//     background-size: cover;
//     height: 17vh;
//     background-position: top center;
//   }
//   @media (max-width: 345px) {
//     background-size: cover;
//     height: 14vh;
//     background-position: top center;
//   }
//       `;
//     }
//   }}

// `;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0)
  ); /* Stronger gradient */
  pointer-events: none; /* Ensures overlay doesn’t interfere with clicks */
`;

const WhiteBox = styled.div`
  position: absolute;
  top: 50%;
  left: 20%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border-radius: 8px;

  text-align: center;
  font-size: 18px;
  font-weight: bold;
  @media (max-width: 768px) {
    left: 35%; /* Center it horizontally on small screens */
    top: 60%;
  }
`;

const InputContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%; /* Adjust width as needed */
  max-width: 800px; /* Optional: Limit the width of the input box */
  padding: 0 20px; /* Optional: Add padding for smaller screens */
  z-index: 2;
`;

const StyledInput = styled.input`
  border: none;
  border-radius: 4px;
  background: white;
  font-size: 12px;
  padding: 8px 16px;
  outline: none;
  width: 100%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 3;
`;
const CenteredContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 800px;
  padding: 20px;
`;

const Center = ({
  fetchCountries,
  setSearchQuery,
  searchQuery,
  selectedEducationType,
  setSelectedEducationType,
}) => {
  const { universityId } = useParams();
  const { isDropdownVisible, setDropdownVisible } = useDropdown();
  const dropdownRef = useRef(null);
  const location = useLocation();
  const [courses, setCourses] = useState([]);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [searchCourseQuery, setsearchCourseQuery] = useState("");
  const countryId = new URLSearchParams(location.search).get("countryId");
  const [educationTypeName, setEducationTypeName] = useState("");
  const [homeBanner, setHomeBanner] = useState(null);
  const [homeBannerCarousel, setHomeBannerCarousel] = useState([]);
  const [loading, setLoading] = useState(true);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: true,
    pauseOnHover: false,
    cssEase: "linear",
  };

  const MyCarousel = ({ banners }) => {
    const bannerList = Array.isArray(banners) ? banners : [];

    if (loading) {
        return (
            <div className="lg:rounded-[20px] lg:overflow-hidden flex items-center justify-center 
                h-[130px] sm:h-[250px] md:h-[300px] lg:h-[400px] w-full bg-gray-200">
                <p className="text-gray-500 text-sm sm:text-base">Loading...</p>
            </div>
            
        );
    }

    if (bannerList.length === 0) {
      return (
        <div className="lg:rounded-[20px] lg:overflow-hidden">
          <img src="homenew.PNG" alt="Slide 1" className="w-full h-full object-cover" />
        </div>
        
      );
    }

    const adjustedSettings = {
        ...settings,
        autoplay: bannerList.length > 1,
        infinite: bannerList.length > 1,
        dots: bannerList.length > 1,
        arrows: bannerList.length > 1,
    };
  
    return (
        <div className="relative">
        <div className="lg:rounded-[20px] lg:overflow-hidden">
            {bannerList.length === 1 ? (
                <img
                    src={bannerList[0].web_banner_img}
                    alt={`Banner ${bannerList[0].id}`}
                    className="w-full h-full object-cover"
                />
            ) : (
                <Slider {...adjustedSettings}>
                    {bannerList.map((banner) => (
                        <div key={banner.id} className="lg:overflow-hidden">
                            <a href={banner.banner_url} target="_blank" rel="noopener noreferrer">
                                <img
                                    src={banner.web_banner_img}
                                    alt={`Banner ${banner.id}`}
                                    className="w-full h-full object-cover"
                                />
                            </a>
                        </div>
                    ))}
                </Slider>
            )}
        </div>
    </div>
    );
  };





  const [title, setTitle] = useState("Masters");
  useEffect(() => {
    if (selectedEducationType && selectedEducationType.name) {
      setTitle(selectedEducationType.name);

      localStorage.setItem(
        "selectedEducationType",
        JSON.stringify(selectedEducationType)
      );
    } else {
      setTitle("Masters");
    }
  }, [selectedEducationType]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setDropdownVisible]);

  const [universityDetails, setUniversityDetails] = useState(null);
  const [backgroundImage, setBackgroundImage] = useState(null);
  const handleResize = () => {
    if (window.innerWidth <= 501) {
      setBackgroundImage(homeBanner || "/mobileimage.png");
    } else {
      // setBackgroundImage(universityDetails?.banner_image || '/home.png');
      setBackgroundImage(homeBanner || "/home.png");
    }
  };

  useEffect(() => {
    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [homeBanner]);

  useEffect(() => {
    const fetchUniversityDetails = async () => {
      try {
        const response = await axiosInstance.get(
          `home/api/v1/student-university-detail/${universityId}/`
        );
        setUniversityDetails(response.data);
      } catch (err) {
        console.error("Error fetching university details:", err.message);
      }
    };

    if (universityId) {
      fetchUniversityDetails();
    }
  }, [universityId]);

  // const fetchBannerdetails = async () => {
  //   try {
  //     const response = await axiosInstance.get(
  //       `account/api/v1/home-company-settings`
  //     );
  //     setHomeBanner(response.data.web_banner_img);
  //     console.log(response.data.web_banner_img, "hello banner");
  //   } catch (err) {
  //     console.error("Error fetching university details:", err.message);
  //   }
  // };
  const page = selectedEducationType.name

  const fetchBannerdetails = async (page) => {
    try {
        const response = await axiosInstance.get(
           `account/api/v1/home-company-settings/?page=${page}`
        );
        setHomeBannerCarousel(response.data.banners);
    } catch (err) {
        console.error("Error fetching university details:", err.message);
    } finally {
        setLoading(false); // Set loading to false after fetch
    }
};

  useEffect(() => {
    if (selectedEducationType?.name) {
      fetchBannerdetails(selectedEducationType.name);
    }
  }, [selectedEducationType]);

  const [isSidebarVisible, setSidebarVisible] = useState(false);
  const [userInfo, setUserInfo] = useState(null);

  const navigate = useNavigate();

  const handleLogoClick = () => {
    localStorage.removeItem("selectedEducationType");
    navigate("/");
  };

  useEffect(() => {
    const accessToken = localStorage.getItem("access");
    const refreshToken = localStorage.getItem("refresh");
    const storedUserInfo = localStorage.getItem("user_info");

    if (accessToken && refreshToken && storedUserInfo) {
      setUserInfo(JSON.parse(storedUserInfo));
    }
  }, []);

  const clientId =
    "709840142800-eqorq7htnsgb4ahrv707a6i5q7m8ul30.apps.googleusercontent.com";
  const redirectUri = "https://takemeabroad.me/auth/google/callback";
  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  const handleLogin = () => {
    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&scope=email%20profile&service=lso&o2v=2&ddm=0&flowName=GeneralOAuthFlow`;
    window.location.href = authUrl;
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const fetchCourses = (url) => {
    let requestUrl = `${url}`;

    // Make the API request
    axiosInstance
      .get(requestUrl)
      .then((response) => {
        setCourses(response.data.results); // Set the courses from response
        setNextPage(response.data.next); // Set the next page URL
        setPreviousPage(response.data.previous); // Set the previous page URL
      })
      .catch((error) => {
        console.error("Error fetching courses:", error);
      });
  };

  useEffect(() => {
    let baseUrl = "home/api/v1/student-course-list/?limit=4";

    // Append the `country` parameter only if `countryId` exists
    if (countryId && countryId !== "null" && countryId !== "undefined") {
      baseUrl += `&country=${countryId}`;
    }

    // Add additional query params if needed, like `searchCourseQuery`
    if (searchCourseQuery) {
      baseUrl += `&search=${searchCourseQuery}`;
    }

    fetchCourses(baseUrl);
  }, [countryId, searchCourseQuery]);

  return (
    <div className="px-2.5 md:px-0 w-full overflow-hidden">
      <div className="lg:px-16">
        <MyCarousel banners={homeBannerCarousel} />
      </div>
      {location.pathname.startsWith("/university") && <Overlay />}
      <div
        className="relative w-full"
        style={{
          zIndex: 2,
        }}
      >
        <div className="flex items-center justify-between py-2.5 px-6 lg:px-20 xl:px-28 w-full">
          {isSidebarVisible && (
            <SideBar isVisible={isSidebarVisible} onClose={toggleSidebar} />
          )}
        </div>
      </div>

      <div className="px-4 md:px-0 md:py-0 w-full">
        <div className="px-2 md:px-0 md:py-0 w-full">
          {isDropdownVisible && (
            <div
              ref={dropdownRef}
              className="absolute top-2 md:top-6 right-4 md:right-8 px-2 md:px-6 bg-white border rounded shadow-lg z-10"
            >
              <div className="py-1 md:py-1 cursor-pointer text-[13px] pt-sans-regular">
                <Logout />
              </div>
            </div>
          )}

          {location.pathname.startsWith("/university") && (
            <div className="flex gap-4 w-full">
              <div className="flex flex-col">
                <div className="flex gap-2 items-center">
                  <div>
                    <img
                      src={universityDetails?.logo_image}
                      className="w-[90px] h-[60px] lg:w-[100px] lg:h-[100px] object-cover"
                    />
                  </div>
                  <div>
                    <div className="text-lg md:text-xl pt-sans-bold text-white">
                      {universityDetails?.name}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>

  );
};

export default Center;
