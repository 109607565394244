import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router';
import tokenInstance from '../components/axiosInstance/tokenInstance';
import { createClient } from 'agora-rtc-sdk-ng';
import AgoraRTC from 'agora-rtc-sdk-ng';
import { useMentorContext } from './MentorContext';
import { Modal, Box } from '@mui/material';
import MicOffIcon from '@mui/icons-material/MicOff';
import MicIcon from '@mui/icons-material/Mic';
import CloseIcon from '@mui/icons-material/Close';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import Bottom from './Bottom';
import { motion } from "framer-motion";


const CallWaiting = () => {
    const location = useLocation();



    const [studentId, setStudentId] = useState(null);
    const [socket, setSocket] = useState(null);
    const [requestSocket, setRequestSocket] = useState(null);
    const [agoraToken, setAgoraToken] = useState(null);
    const [client, setClient] = useState(null);
    const [isJoined, setIsJoined] = useState(false);
    const [channelName, setChannelName] = useState(null);
    const [uid, setUid] = useState(null);
    const [isMuted, setIsMuted] = useState(false); // Mute state
    const [localAudioTrack, setLocalAudioTrack] = useState(null); // Track state
    const navigate = useNavigate();
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [allowNavigation, setAllowNavigation] = useState(false)
    const [isSpeakerOn, setIsSpeakerOn] = useState(false);
    const observerRef = useRef(null);
    const [nextPage, setNextPage] = useState(null); // Store next page URL
    const [loading, setLoading] = useState(false); // Track loading state

    const [requestList, setRequestList] = useState([]);


    const appId = 'f9396bbbaad64783abd978a28e727ce2';
    const {
        callResponse,
        fetchStudentRequestList,



    } = useMentorContext();

    const roomId = callResponse?.room?.id
    console.log(roomId, "rooooooooooooooooom")




    const fetchRequestList = async (url = `calls/api/v1/voice-call-room-request-list/?room_id=${roomId}&limit=10`) => {
        if (!url || loading) return; // Avoid unnecessary API calls

        setLoading(true);
        try {
            const response = await tokenInstance.get(url);

            setRequestList((prevRequests) => {
                const newRequests = response.data.results.filter(
                    (newRequest) => !prevRequests.some((req) => req.id === newRequest.id)
                );
                return [...prevRequests, ...newRequests]; // Append new unique results
            });

            setNextPage(response.data.next); // Update next page URL
        } catch (err) {
            console.error("Error fetching request list:", err.message);
        }
        setLoading(false);

    };



    useEffect(() => {
        fetchRequestList();
    }, [])
    useEffect(() => {
        const observerCallback = (entries) => {
            if (entries[0].isIntersecting && nextPage) {
                fetchRequestList(nextPage); // Fetch the next page
            }
        };

        if (!observerRef.current) {
            observerRef.current = new IntersectionObserver(observerCallback, { threshold: 1 });
        }

        const triggerElement = document.getElementById("load-more-trigger");
        if (triggerElement) {
            observerRef.current.observe(triggerElement);
        }

        return () => {
            if (observerRef.current) {
                observerRef.current.disconnect();
                observerRef.current = null;
            }
        };
    }, [nextPage]);
    // useEffect(() => {
    //     if (callResponse && callResponse.room.id) {
    //         setRoomID(callResponse.room.id)


    //         setStudentId(callResponse.student?.id);
    //     }
    // }, [callResponse]);







    const userInfo = JSON.parse(localStorage.getItem('user_info'));
    const userId = userInfo?.id;


    useEffect(() => {
        if (userId) {
            const wsUrl = `wss://norvelbackend.site/ws/voice-call-token/voice_call_room_${userId}/`;
            const newSocket = new WebSocket(wsUrl);
            setSocket(newSocket);

            newSocket.onopen = () => {
                console.log('WebSocket connection established.');
            };

            newSocket.onmessage = (event) => {
                try {
                    const message = JSON.parse(event.data);
                    if (message.type === 'voice_call_websocket_receiver') {
                        setAgoraToken(message.event.token);
                        setUid(message.event.uid);
                        setChannelName(message.event.channel_name);
                    }
                } catch (error) {
                    console.error('Error parsing WebSocket message:', error);
                }
            };

            newSocket.onerror = (error) => {
                console.error('WebSocket error:', error);
            };

            newSocket.onclose = (event) => {
                console.log('WebSocket connection closed:', event);
            };

            return () => {
                newSocket.close();
            };
        }
    }, [userId]);
    useEffect(() => {
        if (userId) {
            // WebSocket for voice call requests
            const wsRequestUrl = `wss://norvelbackend.site/ws/voice-call-requests/voice_call_requests_${userId}/`;
            const newRequestSocket = new WebSocket(wsRequestUrl);
            setRequestSocket(newRequestSocket);

            newRequestSocket.onopen = () => {
                console.log('Request WebSocket connection established.');
            };

            newRequestSocket.onmessage = (event) => {
                console.log('Message received:', event.data);
                fetchRequestList();
            };

            newRequestSocket.onerror = (error) => {
                console.error('Request WebSocket error:', error);
            };

            newRequestSocket.onclose = (event) => {
                console.log('Request WebSocket connection closed:', event);
            };

            // Clean up on unmount
            return () => {
                newRequestSocket.close();
            };
        }
    }, [userId]);

    const joinAgoraChannelStudent = async () => {
        if (!agoraToken || !channelName || !uid) {
            console.error("Missing Agora token, channel name, or UID.");
            return;
        }

        try {
            const agoraClient = createClient({ mode: 'rtc', codec: 'vp8' });
            setClient(agoraClient);

            // Create and publish local audio track
            const track = await AgoraRTC.createMicrophoneAudioTrack();
            setLocalAudioTrack(track);
            await agoraClient.join(appId, channelName, agoraToken, uid);
            await agoraClient.publish([track]);
            console.log("Student audio track published.");

            // Handle remote user subscriptions
            agoraClient.on("user-published", async (user, mediaType) => {
                await agoraClient.subscribe(user, mediaType);
                console.log("Subscribed to remote user:", user.uid);

                if (mediaType === "audio") {
                    const remoteAudioTrack = user.audioTrack;
                    remoteAudioTrack.play();
                    console.log("Playing remote audio from user:", user.uid);
                }
            });

            agoraClient.on("user-unpublished", (user) => {
                console.log("Remote user unpublished:", user.uid);
            });

            setIsJoined(true);
        } catch (error) {
            console.error("Error joining Agora channel (Student):", error);
        }
    };


    const toggleMute = () => {
        if (localAudioTrack) {
            if (isMuted) {
                localAudioTrack.setEnabled(true); // Unmute
                console.log("Microphone unmuted.");
            } else {
                localAudioTrack.setEnabled(false); // Mute
                console.log("Microphone muted.");
            }
            setIsMuted(!isMuted);
        }
    };

    const leaveAgoraChannel = async () => {
        if (client) {
            try {
                await client.leave();
                setIsJoined(false);
                setLocalAudioTrack(null); // Clear local track
                console.log('Left Agora channel.');
            } catch (error) {
                console.error('Error leaving Agora channel:', error);
            }
        }
    };

    useEffect(() => {
        if (agoraToken && channelName && uid) {
            joinAgoraChannelStudent();
        }
    }, [agoraToken, channelName, uid]);

    const callEnd = async () => {
        if (!roomId) {
            console.error("Room ID is missing.");

            return;
        }

        try {
            const response = await tokenInstance.post("calls/api/v1/disconnect_voice_call/", {
                room: roomId,
            });

            if (response.status === 200) {
                console.log("Call disconnected successfully.");


                if (client) {
                    await client.leave();
                    client.remoteUsers.forEach((user) => {
                        if (user.audioTrack) {
                            user.audioTrack.stop();
                            user.audioTrack.close();
                        }
                    });

                    if (localAudioTrack) {
                        localAudioTrack.stop();
                        localAudioTrack.close();
                    }

                    setClient(null);
                    setLocalAudioTrack(null);
                    setIsJoined(false);
                }

                navigate("/mentor");
            } else {
                console.error("Failed to disconnect call. Status:", response.status);
            }
        } catch (error) {
            console.error("Error ending the call:", error);
        }
    };

    useEffect(() => {
        const handleBeforeUnload = (event) => {


            event.preventDefault();
            callEnd()




            return "";
        };


        // Attach the event listener
        window.addEventListener("beforeunload", handleBeforeUnload);

        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, []);
    const EndStudentCall = async () => {
        const navigate = useNavigate();

        try {
            const response = await tokenInstance.post("calls/api/v1/disconnect_voice_call/", {
                room: roomId,
            });

            if (response.status === 200) {
                console.log("Call disconnected successfully.");
                navigate('/mentor');  // Navigate to the /mentor page
            }
        } catch (error) {
            console.error("Error disconnecting the call:", error);
        }
    };

    const toggleSpeaker = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

            // Get all audio output devices
            const devices = await navigator.mediaDevices.enumerateDevices();

            // Find audio output devices (speakers)
            const speakerDevices = devices.filter(device => device.kind === 'audiooutput');

            if (speakerDevices.length > 0) {
                const speaker = speakerDevices[0].deviceId; // Get the first speaker
                const audioElement = document.querySelector('audio'); // Assuming you have an audio element for the call

                if (isSpeakerOn) {
                    // Switch to earpiece (you would use an earpiece or default device here)
                    audioElement.setSinkId(null); // This should revert to the default (earpiece or system audio)
                } else {
                    // Switch to speakerphone (using the first available speaker)
                    audioElement.setSinkId(speaker);
                }
                setIsSpeakerOn(!isSpeakerOn); // Toggle the state
            }
        } catch (error) {
            console.error('Error accessing media devices:', error);
        }
    };















    return (
        <div
            className='flex  flex-col  pt-6 min-h-screen '
            style={{ backgroundImage: "url('/bgimg.png')", backgroundSize: "cover", backgroundPosition: "center" }}

        >

            <div className="flex flex-col items-center justify-center rounded-md  ">


                <>
                    <div className='flex items-center justify-center pt-32 md:pt-0  flex-col'>
                        <div className='relative w-[150px] h-[150px] md:w-[200px] md:h-[200px]'>
                            <div className='absolute inset-0 border-2 border-green-500  rounded-full animate-opacity-pulse m-[-12px]'></div>
                            <div className='absolute inset-0 border-2 border-green-500  rounded-full animate-opacity-pulse m-[-6px]'></div>
                            {/* Outer border with pulse effect */}
                            <img src="/img2.jpg" className='w-[100%] h-[100%] object-cover rounded-full' />
                        </div>
                        <div className='text-[11px] pt-sans-regular pt-4'>
                            {callResponse?.room?.mentor?.first_name} <span>{callResponse?.room?.mentor?.last_name}</span>
                        </div>
                        <div className='text-[11px] pt-sans-regular text-white '>
                            ( Mentor )
                        </div>
                    </div>



                </>
                {!isJoined ?
                    (
                        <>
                            <div className='text-[12px] my-6  px-4 py-1 rounded-full pt-sans-bold text-white'>Mentor will accept the call In a moment.</div>

                        </>

                    ) : (
                        ""

                    )
                }

            </div>



            {isJoined ? (
                <div className='flex item-center justify-center gap-8 mt-auto py-6 px-4'>

                    <div>
                        <div
                            className='cursor-pointer'
                            onClick={toggleMute}

                        >

                            {isMuted ? <div className='bg-white opacity-45 rounded-full px-4 py-4'><MicOffIcon style={{ fontSize: '25px' }} /></div> : <div className='bg-white opacity-45 rounded-full px-4 py-4'>< MicIcon style={{ fontSize: '25px' }} /> </div>}
                        </div>
                    </div>
                    <div>
                        <div
                            className='cursor-pointer'

                        >

                            <div className='bg-white opacity-45 rounded-full px-4 py-4'>
                                <VolumeUpIcon style={{ fontSize: '25px' }} />
                            </div>

                        </div>
                    </div>
                    <div>
                        <div
                            onClick={callEnd}
                            className=" py-4 px-4 text-[12px] rounded-full cursor-pointer bg-red-600 text-white"

                        >
                            <CloseIcon style={{ fontSize: '25px' }} />

                        </div>
                    </div>

                </div>


            ) :



                <motion.div
                    className='bg-gray-100'
                    style={{ borderRadius: "20px 20px 0 0" }}
                    initial={{ y: "100%" }}  // Start off-screen (bottom)
                    animate={{ y: 0 }}       // Slide up into view
                    transition={{ duration: 0.5, ease: "easeOut" }}
                >



                    <div className='bg-gray-100 mt-auto' style={{ borderRadius: "20px 20px 0 0" }}>



                        <div className=' h-[300px] overflow-y-auto  ' >
                            <div className="pt-sans-regular px-4 py-2 text-[14px]">Waiting list</div>
                            {requestList?.map((request, index) => {
                                return (
                                    <div key={index} className="flex gap-4  px-2 py-2  items-center border border-gray-300 shadow-md mx-4 rounded-md">
                                        <div className='w-8 h-8'>
                                            <img src="/img3.jpg" className='w-[100%] h-[100%] object-cover rounded-full' />
                                        </div>
                                        <div className='flex flex-col w-full '>
                                            {request.student?.id === userId ? (
                                                <div className='flex   justify-between items-center w-full'>
                                                    <div>
                                                        <div className="text-[13px] pt-sans-bold">You</div>
                                                    </div>


                                                    <div className='flex items-center justify-center ' onClick={EndStudentCall}>
                                                        <div className='text-[12px] pt-sans-bold rounded-full cursor-pointer px-4 py-1 border border-green-300 '>End Call</div>
                                                    </div>
                                                </div>

                                            ) :
                                                (
                                                    <div className="text-[13px] pt-sans-bold">{request.student?.first_name}</div>
                                                )

                                            }

                                        </div>
                                    </div>
                                );
                            })}
                        </div>



                    </div>
                </motion.div>




            }




            < Bottom />


        </div>


    );



};

export default CallWaiting;


