import React from 'react'
import RoomRequistList from '../RoomRequistList'
import StudentHeader from '../StudentHeader'

const CreateRoom = () => {
  return (
    <div>
       <StudentHeader/>
       <RoomRequistList/>
       </div>
  )
}

export default CreateRoom