import React, { useEffect, useState, useCallback } from 'react';
import Header from '../components/header/Header'
import Center from '../components/center/Center'

import Footer from '../components/footer/Footer'
import axiosInstance from '../components/axiosInstance/axiosInstance';

import Countries from '../components/countries/Countries'
import CourseFilter from '../components/tabs/CourseFilter';
import Tabs from '../components/tabs/Tabs';
import { ITEMS_PER_PAGE } from '../config/config';
import { DropdownProvider } from '../components/DropdownContext/DropdownContext';
import { debounce } from 'lodash';
import { useSearchParams } from 'react-router-dom';

const Home = () => {
  const [countries, setCountries] = useState([]);

  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [count, setCount] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [educationTypeCounts, setEducationTypeCounts] = useState([]);
  const [selectedEducationType, setSelectedEducationType] = useState('');
  const [marks, setMarks] = useState('');
  const [feesFrom, setFeesFrom] = useState('');
  const [feesTo, setFeesTo] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [educationCount, setEducationCount] = useState();
  const [matchedEducationDetails, setMatchedEducationDetails] = useState(null);



  useEffect(() => {

    const storedEducationType = localStorage.getItem('selectedEducationType');
    if (storedEducationType) {
      try {
        setSelectedEducationType(JSON.parse(storedEducationType)); // Parse only if it's valid
      } catch (error) {
        console.error('Error parsing selectedEducationType from localStorage:', error);
        localStorage.removeItem('selectedEducationType'); // Remove invalid item
      }
    }
  }, []);
  useEffect(() => {
    const initialMarks = searchParams.get('marks') || '';
    const initialFeesTo = searchParams.get('feesTo') || '';
    const initialFeesFrom = searchParams.get('feesFrom') || '';


    setMarks(initialMarks);
    setFeesTo(initialFeesTo);
    setFeesFrom(initialFeesFrom);

  }, [searchParams]);



  
  const fetchCountries = async () => {
    // Avoid calling the API if no filters are applied
    if (!searchQuery && !selectedEducationType && !marks && !feesFrom && !feesTo) {
      return;
    }
  
    setLoading(true);
    try {
      let url = `home/api/v1/country-list/?limit=9&search=${searchQuery}`;
  
      // Add education_type only if selected
      if (selectedEducationType) {
        url += `&education_type=${selectedEducationType.id}`;
      }
  
      if (marks) {
        url += `&marks=${marks}`;
      }
  
      if (feesFrom) {
        url += `&fees_from=${feesFrom}`;
      }
  
      if (feesTo) {
        url += `&fees_to=${feesTo}`;
      }
  
      const response = await axiosInstance.get(url);
  
      setCountries(response.data.results);
      setNextPage(response.data.next);
      setPreviousPage(response.data.previous);
      setCount(response.data.count);
  
      response.data.results.forEach((country) => {
        const educationLevelCount = country.education_level_count;
  
        if (Array.isArray(educationLevelCount)) {
          const educationCounts = educationLevelCount.reduce((acc, item) => {
            acc[item.name] = item.count;
            return acc;
          }, {});
  
          setEducationCount((prevCounts) => ({
            ...prevCounts,
            [country.id]: educationCounts,
          }));
        }
      });
  
      setTotalPages(Math.ceil(response.data.count / ITEMS_PER_PAGE));
    } catch (error) {
      console.error('Error fetching country data:', error);
    } finally {
      setLoading(false);
    }
  };
  


  // const fetchCountries = async () => {
  //     setLoading(true);
  //     try {
  //       const educationType = selectedEducationType ? selectedEducationType.id : '1';
  //       console.log(educationType, "hello count");

  //       let url = `home/api/v1/country-list/?limit=9&search=${searchQuery}&education_type=${educationType}`;

  //       if (marks) {
  //         url += `&marks=${marks}`;
  //       }

  //       if (feesTo) {
  //         url += `&fees_to=${feesTo}`;
  //       }

  //       const response = await axiosInstance.get(url);

  //       setCountries(response.data.results);
  //       console.log(response.data.results, "hello log");
  //       setNextPage(response.data.next);
  //       setPreviousPage(response.data.previous);
  //       setCount(response.data.count);

  //       // Loop through the results to extract education_level_count for each country
  //       response.data.results.forEach((country) => {
  //         const educationLevelCount = country.education_level_count;

  //         if (Array.isArray(educationLevelCount)) {
  //           // Create an object to store the count for each education level
  //           const educationCounts = educationLevelCount.reduce((acc, item) => {
  //             acc[item.name] = item.count; // Store count by education level name
  //             return acc;
  //           }, {});

  //           console.log("Education Level Counts for", country.name, educationCounts);

  //           // You can store the education counts for each country if needed
  //           setEducationCount((prevCounts) => ({
  //             ...prevCounts,
  //             [country.id]: educationCounts, // Store for each country using its ID
  //           }));
  //         } else {
  //           console.warn("education_level_count is undefined or not an array.");
  //         }
  //       });

  //       setTotalPages(Math.ceil(response.data.count / ITEMS_PER_PAGE));
  //     } catch (error) {
  //       console.error('Error fetching country data:', error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };
  const debouncedFetchCountries = useCallback(
    debounce(() => {
      fetchCountries();
    }, 500),
    [searchQuery, selectedEducationType, marks, feesFrom, feesTo]
  );

  useEffect(() => {
    debouncedFetchCountries();
  }, [searchQuery, selectedEducationType, marks, feesFrom, feesTo]);

  useEffect(() => {

    if (selectedEducationType) {
      localStorage.setItem('selectedEducationType', JSON.stringify(selectedEducationType)); // Ensure to stringify
    } else {
      localStorage.removeItem('selectedEducationType'); // Clear if none selected
    }
  }, [selectedEducationType]);

  return (
    <div>
      <DropdownProvider>

        <div>
          <div className='lg:mx-10'>
          <Header fetchCountries={fetchCountries} setFeesTo={setFeesTo} setMarks={setMarks} />
          </div>
          <div className='block md:hidden'>
            <CourseFilter
              setSelectedEducationType={setSelectedEducationType}
              selectedEducationType={selectedEducationType}
              setEducationTypeCounts={setEducationTypeCounts}
              educationTypeCounts={educationTypeCounts}
            />
            
             
            

          </div>

          <Center fetchCountries={fetchCountries} searchQuery={searchQuery} setSearchQuery={setSearchQuery} setSelectedEducationType={setSelectedEducationType} selectedEducationType={selectedEducationType} />

        </div>
        <Tabs searchQuery={searchQuery} count={count} setSearchQuery={setSearchQuery} setEducationTypeCounts={setEducationTypeCounts} educationTypeCounts={educationTypeCounts} setSelectedEducationType={setSelectedEducationType} selectedEducationType={selectedEducationType} fetchCountries={(url, newMarks, newFeesFrom, newFeesTo) => {
          setMarks(newMarks);
          setFeesFrom(newFeesFrom);
          setFeesTo(newFeesTo);
          fetchCountries(url, newMarks, newFeesFrom, newFeesTo);
        }}
        />


        <div>
          <Countries educationCount={educationCount} totalPages={totalPages} previousPage={previousPage} setCurrentPage={setCurrentPage} nextPage={nextPage} currentPage={currentPage} countries={countries} fetchCountries={fetchCountries} count={count} setEducationTypeCounts={setEducationTypeCounts} educationTypeCounts={educationTypeCounts} setSelectedEducationType={setSelectedEducationType} selectedEducationType={selectedEducationType} loading={loading} marks={marks} feesFrom={feesFrom} feesTo={feesTo} />

        </div>

        <div className=''>
          <Footer />
        </div>
      </DropdownProvider>





    </div>
  )
}

export default Home
