import React from 'react'
import HomeIcon from '@mui/icons-material/Home';
import CallIcon from '@mui/icons-material/Call';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

const Bottom = () => {
  return (
    <div className=' sticky bottom-0 left-0 w-full flex justify-between bg-white px-10 py-2  '>


        <div className='flex flex-col items-center'>
            <div><HomeIcon/></div>
            <div className='text-[12px] pt-sans-bold'>Home</div>

        </div>
        <div className='flex flex-col items-center'>
            <div><CallIcon/></div>
            <div className='text-[12px] pt-sans-bold'>Rooms</div>

        </div>
        <div className='flex flex-col items-center'>
            <div><DragIndicatorIcon/></div>
            <div className='text-[12px] pt-sans-bold'>Services</div>

        </div>
        <div className='flex flex-col items-center'>
            <div className='w-6 h-6 '>
                <img src='./img3.jpg' className='w-[100%] h-[100%] rounded-full object-cover'/>
            </div>
            <div className='text-[12px] pt-sans-bold'>Profile</div>

        </div>
        
        





    </div>
  )
}

export default Bottom