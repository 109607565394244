import React from 'react'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

const UniversityDropdown = ({ setUniversityOpen, options, universityOpen, toggleDropdown, selectedOption, onSelect }) => {



  const handleSelect = (option) => {
    onSelect(option);
    setUniversityOpen(false);
  };
  return (
    <div>
      <div >
        <div className="relative inline-block w-full">
          <div
            className="border border-gray-300 rounded-md w-full bg-white   text-[13px] py-2.5 px-4 text-gray-700 leading-tight flex justify-between items-center outline-none"
            onClick={toggleDropdown}
          >
            <div className={`flex-1 ${!selectedOption ? 'text-gray-400' : 'text-gray-700'}`}>
              {selectedOption ? selectedOption : 'Select Option'}
            </div>
            <div> {universityOpen ? <FiChevronUp /> : <FiChevronDown />}</div>

          </div>
          {universityOpen && (
            <ul className="absolute z-10 mt-1 w-full bg-white border border-gray-400 rounded-md shadow-lg">
              {options.map((option, index) => (
                <li
                  key={index}
                  className="px-4 py-2 cursor-pointer hover:bg-black hover:text-white text-sm"
                  onClick={() => handleSelect(option)}
                >
                  <div className='text-[12px]'>{option}</div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>






    </div>
  )
}

export default UniversityDropdown