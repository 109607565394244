import React, { useState, useEffect, useRef } from 'react'
import tokenInstance from '../components/axiosInstance/tokenInstance'
import { useNavigate } from 'react-router'
import Bottom from './Bottom'

const RoomRequistList = () => {
    const [requestList, setRequistList] = useState([])
    const [roomId, setRoomId] = useState(null);
    const [notifyRequestList, setNotifyRequestList] = useState([]);
    const [selectedRequests, setSelectedRequests] = useState([]);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const observer = useRef();

    const navigate = useNavigate()

    const handleCreateRoom = async () => {
        try {
            const response = await tokenInstance.post("/calls/api/v1/voice-call-room-create/")
            console.log(response.data)
            setRoomId(response.data.id)
            console.log(response.data.id, "hellooooo")
            if (response.status === 201) {
                navigate('/mentorcallwaiting', { state: { roomId: response.data } });
            }



        } catch (err) {
            console.log("error in post method ", err.message)
        }



    }

    const fetchRequestList = async () => {

        try {
            if (roomId) {
                const response = await tokenInstance.get(`calls/api/v1/voice-call-room-request-list/?room_id=${roomId}`)
                setRequistList(response.data)

            }
        } catch (err) {
            console.log("error fetching api ", err.message)
        }

    }

    useEffect(() => {
        fetchRequestList()

    }, [roomId])


    const fetchNotifyRequestList = async () => {
        try {
            const response = await tokenInstance.get(`calls/api/v1/notify_student_request_list/?limit=10&offset=${(page - 1) * 10}`);
            const newData = response.data.results;
    
            if (newData.length === 0) {
                setHasMore(false); // Stop fetching if no more data
            } else {
                setNotifyRequestList((prev) => {
                    const uniqueData = newData.filter(item => 
                        !prev.some(existing => existing.id === item.id)
                    );
                    return [...prev, ...uniqueData]; 
                });
            }
        } catch (err) {
            console.error("Error fetching response from API:", err.message);
        }
    };
    

  
    useEffect(() => {
        fetchNotifyRequestList();
    }, [page]); // Fetch data when the page changes



    const handleNotify = async () => {
        if (selectedRequests.length === 0) {
            alert("Please select at least one request.");
            return;
        }

        try {
            const payload = { request_ids: selectedRequests };
            await tokenInstance.post("calls/api/v1/notify-students/", payload);
            alert("Notification sent successfully!");
            setSelectedRequests([]); // Reset selection after success
        } catch (error) {
            console.error("Error notifying students:", error.message);
        }
    };
    const handleSelect = (id) => {
        setSelectedRequests((prevSelected) =>
            prevSelected.includes(id)
                ? prevSelected.filter((reqId) => reqId !== id) // Remove if already selected
                : [...prevSelected, id] // Add if not selected
        );
    };



    return (
        <div>
        <div className='px-4 py-4 relative flex flex-col min-h-screen'>
            {/* <div>
                {requestList.map((request, index) => {
                    return (
                        <div key={index} className='flex gap-10'>
                            <div>
                                {request.student?.first_name}
                            </div>
                            <div>
                                {request.status}
                            </div>
                        </div>

                    );

                })}
            </div> */}

            <div className='flex justify-between px-3 py-3 bg-[#F5DDBF] rounded-md'>
                <div className='pt-sans-regular text-[13px]'>Total Earings</div>
                <div className='pt-sans-bold text-[16px] '>RS: 54,600</div>
            </div>

            <div className='text-[16px] py-4 pt-sans-bold'>Your Call</div>
            <div className="flex gap-4">
                <div className='text-[12px] pt-sans-bold border border-gray-500 px-3 py-1 rounded-full'>Call Request</div>
                <div className='text-[12px] pt-sans-bold border border-gray-500 px-3 py-1 rounded-full'>Call History</div>
                <div className='text-[12px] pt-sans-bold text-white bg-green-500  px-6 py-1 rounded-full'onClick={handleNotify}>Notify</div>
            </div>
            <div>

                <div className='py-4   max-h-[450px]  overflow-y-auto'>
                    {notifyRequestList.map((notify, index) => (

                        <div key={index} className='flex justify-between my-2 items-center border border-gray-200 rounded-md px-4 py-2 shadow-md'>
                            <div className='flex gap-2 items-center'>
                                <div className='w-8 h-8'>
                                <img src={notify.student?.profile_img || "/img3.jpg"} className="w-full h-full object-cover rounded-full" />
                                </div>
                                <div className='text-[13px] pt-sans-bold '>{notify.student?.user?.first_name}</div>
                            </div>
                            <div>
                                <input
                                    type="checkbox"
                                    checked={selectedRequests.includes(notify.id)}
                                    onChange={() => handleSelect(notify.id)}
                                    className="appearance-none w-6 h-6 bg-green-500 rounded-md checked:bg-green-600 checked:border-transparent 
                   checked:after:content-['✔'] checked:after:text-white checked:after:text-lg 
                   flex items-center justify-center"
                                />
                            </div>




                        </div>


                    ))}
                </div>





            </div>






            <div className=' sticky bottom-20 left-0 flex items-center justify-center w-full mt-auto'>
                <div
                    onClick={handleCreateRoom}
                    className="bg-green-500  text-[13px] w-full whitespace-nowrap font-bold  text-white py-2 px-2 rounded-md cursor-pointer text-center hover:bg-green-600"
                >
                    Go Live
                </div>

            </div>
            


        </div>
        <Bottom/>
        </div>
    )
}

export default RoomRequistList