

import React, { useState, useEffect, useRef } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';


const CountryDropDown = ({ options, value, onChange }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionClick = (optionId) => {
        onChange(optionId);
        setIsOpen(false);
    };

    return (
        <div className="custom-dropdown relative" ref={dropdownRef}>
            <div className='flex relative '>
                <div className="selected-option border pt-sans-regular  border-gray-200 rounded-md w-full overflow-y-auto   text-[12px] py-3 px-4 text-gray-600 font-bold inter-semilight " style={{ cursor: 'pointer' }} onClick={handleToggle}>
                    {value ? options.find(option => option.id === value)?.name : 'Select Country'}
                </div>
                <div className='absolute top-2 text-gray-400  right-6'>
                    <KeyboardArrowDownIcon style={{ position: 'absolute', fontSize: '18px' }} />


                </div>
            </div>

            {isOpen && (
                <div className="options  text-[11px] px-2 py-2 absolute z-30 shadow-md w-full max-h-[80px] overflow-y-auto  bg-white  font-bold rounded-md">
                    {options?.length > 0 ? options.map(option => (
                        <div key={option.id} className="option" onClick={() => handleOptionClick(option.id)}>
                            <div className='flex gap-2 items-center hover:bg-gray-50 rounded-full px-2'>
                                <div style={{ backgroundColor: `#${option.bg_color}`, cursor: 'pointer' }} className='w-2 h-2 rounded-full'></div>
                                <div className='py-1 pt-sans-regular '>{option.name}</div>
                            </div>
                        </div>
                    )) : <div className="text-gray-500 text-sm p-2">No countries available</div>}

                </div>
            )}
        </div>
    );
};

export default CountryDropDown;